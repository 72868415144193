import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import CheckBoxOffIcon from '@/components/CustomIcons/CheckboxOffIcon.vue'
import CheckBoxOnIcon from '@/components/CustomIcons/CheckboxOnIcon.vue'
import CalendarIcon from '@/components/CustomIcons/CalendarIcon.vue'
import SearchIcon from '@/components/CustomIcons/SearchIcon.vue'
import MoreIcon from '@/components/CustomIcons/MoreIcon.vue'
import RefreshIcon from '@/components/CustomIcons/RefreshIcon.vue'
import CheckIcon from '@/components/CustomIcons/CheckIcon.vue'
import EditIcon from '@/components/CustomIcons/EditIcon.vue'
import FileIcon from '@/components/CustomIcons/FileIcon.vue'
import CompletedTaskIcon from '@/components/CustomIcons/CompletedTaskIcon.vue'
import LockIcon from '@/components/CustomIcons/LockIcon.vue'
import MailIcon from '@/components/CustomIcons/MailIcon.vue'
import ViewIcon from '@/components/CustomIcons/ViewIcon.vue'
import LogoutIcon from '@/components/CustomIcons/LogoutIcon.vue'
import InspectionOnIcon from '@/components/CustomIcons/InspectionOnIcon.vue'
import ProfileIcon from '@/components/CustomIcons/ProfileIcon.vue'
import InfoIcon from '@/components/CustomIcons/InfoIcon.vue'
import FilterIcon from '@/components/CustomIcons/FilterIcon.vue'
import StarredOnIcon from '@/components/CustomIcons/StarredOnIcon.vue'
import StarredOffIcon from '@/components/CustomIcons/StarredOffIcon.vue'
import DeleteIcon from '@/components/CustomIcons/DeleteIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      checkbox_off: { // name of our custom icon
        component: CheckBoxOffIcon, // our custom component
      },
      checkbox_on: { // name of our custom icon
        component: CheckBoxOnIcon, // our custom component
      },
      calendar_icon: { // name of our custom icon
        component: CalendarIcon, // our custom component
      },
      search_icon: { // name of our custom icon
        component: SearchIcon, // our custom component
      },
      more_icon: { // name of our custom icon
        component: MoreIcon, // our custom component
      },
      refresh_icon: { // name of our custom icon
        component: RefreshIcon, // our custom component
      },
      check_icon: { // name of our custom icon
        component: CheckIcon, // our custom component
      },
      edit_icon: { // name of our custom icon
        component: EditIcon, // our custom component
      },
      file_icon: { // name of our custom icon
        component: FileIcon, // our custom component
      },
      completed_task_icon: { // name of our custom icon
        component: CompletedTaskIcon, // our custom component
      },
      lock_icon: { // name of our custom icon
        component: LockIcon, // our custom component
      },
      mail_icon: { // name of our custom icon
        component: MailIcon, // our custom component
      },
      view_icon: { // name of our custom icon
        component: ViewIcon, // our custom component
      },
      logout_icon: { // name of our custom icon
        component: LogoutIcon, // our custom component
      },
      inspection_on: { // name of our custom icon
        component: InspectionOnIcon, // our custom component
      },
      profile_icon: { // name of our custom icon
        component: ProfileIcon, // our custom component
      },
      info_icon: { // name of our custom icon
        component: InfoIcon, // our custom component
      },
      filter_icon: { // name of our custom icon
        component: FilterIcon, // our custom component
      },
      starred_on_icon: { // name of our custom icon
        component: StarredOnIcon, // our custom component
      },
      starred_off_icon: { // name of our custom icon
        component: StarredOffIcon, // our custom component
      },
      delete_icon: { // name of our custom icon
        component: DeleteIcon, // our custom component
      }
    }
  }
});
