import axios from "axios";

export default {
  state: {
    hasFilters: false,
    keyword: '',
    starred: null,
    generalStatus: '',
    queenAge: '',
    broodFrames: [0, 25],
    populationFrames: [0, 50],
    harvestedFrames: [0, 25],
    honeycombs: [0, 50],
    filteredHiveIds: [],
    selectedFilters: {}
  },
  getters: {
    hasFilters: state => state.hasFilters,
    filteredHiveIds: state => state.filteredHiveIds,
    selectedFilters: state => state.selectedFilters,

    //Status Filters
    keyword: state => state.keyword,
    starred: state => state.starred,
    generalStatus: state => state.generalStatus,
    queenAge: state => state.queenAge,
    broodFrames: state => state.broodFrames,
    populationFrames: state => state.populationFrames,
    harvestedFrames: state => state.harvestedFrames,
    honeycombs: state => state.honeycombs
  },
  mutations: {
    setKeyword(state, payload) {
      state.keyword = payload;
    },
    setStarred(state, payload) {
      state.starred = payload;
    },
    setGeneralStatus(state, payload) {
      state.generalStatus = payload;
    },
    setQueenAge(state, payload) {
      state.queenAge = payload;
    },
    setBroodFrames(state, payload) {
      state.broodFrames = payload;
    },
    setPopulationFrames(state, payload) {
      state.populationFrames = payload;
    },
    setHarvestedFrames(state, payload) {
      state.harvestedFrames = payload;
    },
    setHoneycombs(state, payload) {
      state.honeycombs = payload;
    },
    setHasFilters(state, hasFilters) {
      state.hasFilters = hasFilters;
    },
    setFilteredHives(state, filteredHiveIds) {
      state.filteredHiveIds = filteredHiveIds;
    },
    filteredHives: state => {
      return state.hives.map(hive => {
        let passedFilter = !!state.filteredHiveIds.find(id => id == hive.hiveId);
        return {
          ...hive,
          passedFilter
        }
      }).sort(
        (a, b) =>
        a.q_bee_life_cycles.hives.hiveNumber - b.q_bee_life_cycles.hives.hiveNumber
      );
    },
    addSelectedFilter(state, filter) {
      state.selectedFilters[filter] = state[filter];
    },
    removeSelectedFilter(state, filter) {
      delete state.selectedFilters[filter];
    },
    resetSlider(state, slider) {
      state[slider] = [];
      if (slider == 'honeycombs' || slider == 'populationFrames') {
        state[slider] = [0, 50];
      } else {
        state[slider] = [0, 25];
      }
    },
    resetAllFilters(state) {
      state.hasFilters = false;
      state.filteredHiveIds = [];
      state.selectedFilters = {};
      state.keyword = '';
      state.starred = null;
      state.generalStatus = '';
      state.queenAge = '';
      state.broodFrames = [0, 25];
      state.populationFrames = [0, 50];
      state.harvestedFrames = [0, 25];
      state.honeycombs = [0, 50];
    },
    resetFilter(state, filter) {
      state[filter] = '';
    },
    resetBoolFilter(state, filter) {
      state[filter] = null;
    }
  },
  actions: {
    setHasFilters({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setHasFilters", payload);
        resolve();
      })
    },

    getFilteredHives({commit}, filters) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/filterInspectionResults`, {
          ...filters
        }).then(res => {
          commit("setFilteredHives", []);
          commit("setFilteredHives", res.data);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        })
      })
    },

    getSliderLimits({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v2/apiaryInspectionData`, {
          ...payload
        }).then(res => {
          commit("setSliders", res.data);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        })
      })
    },

    addSelectedFilter ({commit}, filter) {
      return new Promise((resolve, reject) => {
        commit('addSelectedFilter', filter);
        resolve();
      });
    },

    resetAllFilters ({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetAllFilters');
        resolve();
      });
    },

    resetSlider ({commit}, slider) {
      return new Promise((resolve, reject) => {
        commit('resetSlider', slider);
        commit('removeSelectedFilter', slider);
        resolve();
      });
    },

    resetFilter ({commit}, filter) {
      return new Promise((resolve, reject) => {
        commit('resetFilter', filter);
        commit('removeSelectedFilter', filter);
        resolve();
      });
    },

    resetBoolFilter ({commit}, filter) {
      return new Promise((resolve, reject) => {
        commit('resetBoolFilter', filter);
        commit('removeSelectedFilter', filter);
        resolve();
      });
    }
  }
}
