<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="10" cy="10" r="10" fill="#03CFBB"/>
  <path d="M13.75 7.5L8.59375 13.125L6.25 10.5682" stroke="#F7F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
  export default {
    name: 'InspectionOnIcon'
  }
</script>
