<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="10.4899" width="18" height="12" rx="2" stroke="#262338" stroke-width="2"/>
    <path d="M7 10.4899V6.48986C7 5.16378 7.52678 3.89201 8.46447 2.95433C9.40215 2.01664 10.6739 1.48986 12 1.48986C13.3261 1.48986 14.5979 2.01664 15.5355 2.95433C16.4732 3.89201 17 5.16378 17 6.48986V10.4899" stroke="#262338" stroke-width="2"/>
  </svg>
</template>

<script>
  export default {
    name: 'LockIcon'
  }
</script>
