import axios from "axios";

function getPercentages(array) {
  let total = array.reduce((partialSum, a) => partialSum + a.count, 0);
  let percentages = [];

  for (let k = 0; k < array.length; k++) {
    let temp = Math.round((array[k].count * 100 / total) * 100) / 100;
    percentages.push(temp);
  }
  return percentages;
}

export default {
  state: {
    seasonalFromDate: '',
    seasonalToDate: '',
    //assessment
    seasonalGeneralStatus: [],
    //growth
    seasonalPopulationFrames: [],
    seasonalPopulationFramesHives: [],
    seasonalBroodFrames: [],
    seasonalBroodFramesHives: [],
    //harvest
    seasonalHarvestedFrames: [],
    seasonalHarvestedFramesHives: [],
    chosenDateDuration: 365,
    dateRange: null
  },
  getters: {
    seasonalFromDate: state => state.seasonalFromDate,
    seasonalToDate: state => state.seasonalToDate,
    seasonalGeneralStatus: state => state.seasonalGeneralStatus,
    seasonalPopulationFrames: state => state.seasonalPopulationFrames,
    seasonalPopulationFramesHives: state => state.seasonalPopulationFramesHives,
    seasonalBroodFrames: state => state.seasonalBroodFrames,
    seasonalBroodFramesHives: state => state.seasonalBroodFramesHives,
    seasonalHarvestedFrames: state => state.seasonalHarvestedFrames,
    seasonalHarvestedFramesHives: state => state.seasonalHarvestedFramesHives,
    chosenDateDuration: state => state.chosenDateDuration,
    dateRange: state => state.dateRange
  },
  mutations: {
    setSeasonalFromDate(state, value) {
      state.seasonalFromDate = value;
    },
    setSeasonalToDate(state, value) {
      state.seasonalToDate = value;
    },
    setSeasonalGeneralStatus(state, resultArray) {
      let chartData = [
        [
          {type: 'date', label: 'Date'},
          'Excellent',
          {type: 'string', role: 'tooltip','p': {'html': true}},
          'Good',
          {type: 'string', role: 'tooltip','p': {'html': true}},
          'Bad',
          {type: 'string', role: 'tooltip','p': {'html': true}}
        ]
      ];

      for (let k = 0; k < resultArray.length; k++) {
        let ig_date = resultArray[k].ig_date;
        let dateObj = new Date(ig_date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let excellent = resultArray[k].metrics[0].count;
        let good = resultArray[k].metrics[1].count;
        let bad = resultArray[k].metrics[2].count;
        let excellentTooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>hives: '+excellent+'</div>';
        let goodTooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>hives: '+good+'</div>';
        let badTooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>hives: '+bad+'</div>';

        let percentages = getPercentages(resultArray[k].metrics);

        chartData.push([
          new Date(year, month, day),
          percentages[0],
          excellentTooltip,
          percentages[1],
          goodTooltip,
          percentages[2],
          badTooltip
        ]);
      }

      state.seasonalGeneralStatus = chartData;
    },
    setSeasonalPopulationFrames(state, resultArray) {
      let chartData = [
        [
          {type: 'date', label: 'Date'},
          'Population Frames',
          {type: 'string', role: 'tooltip','p': {'html': true}}
        ]
      ];

      for (let k = 0; k < resultArray.length; k++) {
        let ig_date = resultArray[k].ig_date;
        let dateObj = new Date(ig_date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let avg = parseFloat(resultArray[k].ig_avg);
        let aboveAvg = parseFloat(resultArray[k].above);
        let belowAvg = parseFloat(resultArray[k].below);
        let total = aboveAvg + belowAvg;

        let tooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>Avg: '+avg+
          '<br>Above Avg: '+aboveAvg+'<br>Below Avg: '+belowAvg+'</div>';

        chartData.push([new Date(year, month, day), avg, tooltip]);
      }
      state.seasonalPopulationFrames = chartData;
    },
    setSeasonalBroodFrames(state, resultArray) {
      let chartData = [
        [
          {type: 'date', label: 'Date'},
          'Brood Frames',
          {type: 'string', role: 'tooltip','p': {'html': true}}
        ]
      ];

      for (let k = 0; k < resultArray.length; k++) {
        let ig_date = resultArray[k].ig_date;
        let dateObj = new Date(ig_date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let avg = parseFloat(resultArray[k].ig_avg);
        let aboveAvg = parseFloat(resultArray[k].above);
        let belowAvg = parseFloat(resultArray[k].below);
        let total = aboveAvg + belowAvg;

        let tooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>Avg: '+avg+
          '<br>Above Avg: '+aboveAvg+'<br>Below Avg: '+belowAvg+'</div>';

        chartData.push([new Date(year, month, day), avg, tooltip]);
      }

      state.seasonalBroodFrames = chartData;
    },
    setSeasonalHarvestedFrames(state, resultArray) {
      let chartData = [
        [
          {type: 'date', label: 'Date'},
          'Harvested Frames',
          {type: 'string', role: 'tooltip','p': {'html': true}}
        ]
      ];

      for (let k = 0; k < resultArray.length; k++) {
        let ig_date = resultArray[k].ig_date;
        let dateObj = new Date(ig_date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let avg = parseFloat(resultArray[k].ig_avg);
        let aboveAvg = parseFloat(resultArray[k].above);
        let belowAvg = parseFloat(resultArray[k].below);
        let total = aboveAvg + belowAvg;

        let tooltip = '<div class="seasonal-tooltip">'+ig_date+'<br>Avg: '+avg+
          '<br>Above Avg: '+aboveAvg+'<br>Below Avg: '+belowAvg+'</div>';

        chartData.push([new Date(year, month, day), avg, tooltip]);
      }

      state.seasonalHarvestedFrames = chartData;
    },
    setChosenDateDuration(state, value) {
      state.chosenDateDuration = value;
    },
    setDateRange(state, value) {
      state.dateRange = value;
    },
    resetSeasonalCharts(state) {
      state.seasonalGeneralStatus = [];
      state.seasonalPopulationFrames = [];
      state.seasonalBroodFrames = [];
      state.seasonalHarvestedFrames = [];
    },
    resetSeasonalDates(state) {
      state.chosenDateDuration = 365;
      state.dateRange = null;
    }
  },
  actions: {
    seasonalGroupMetrics({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/inspectionGroupMetrics`, {
          ...payload
        }).then(res => {
          if (res.data.result) {
            if (payload.field == 'generalStatus') {
              commit('setSeasonalGeneralStatus', res.data.result);
            } else if (payload.field == 'populationFramesS') {
              commit('setSeasonalPopulationFrames', res.data.result);
            } else if (payload.field == 'broodFramesS') {
              commit('setSeasonalBroodFrames', res.data.result);
            } else if (payload.field == 'harvestedFramesS') {
              commit('setSeasonalHarvestedFrames', res.data.result);
            }
          }
          resolve();
        })
        .catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    setSeasonalFromToDates({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setSeasonalFromDate', payload.fromDate);
        commit('setSeasonalToDate', payload.toDate);
        resolve();
      })
    },
    setDateRange({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setDateRange', date);
        resolve();
      })
    },
    setChosenDateDuration({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setChosenDateDuration', date);
        resolve();
      })
    },
    resetSeasonalCharts({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetSeasonalCharts');
        resolve();
      });
    },
    resetSeasonalDates({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetSeasonalDates');
        resolve();
      });
    }
  }
}
