<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="2.5" r="2.5" fill="#2aa8f8"/>
    <path d="M15.5075 8H6L5 10.5L10 11L6.57474 18.9354C6.57474 18.9354 6.40657 20.1663 6.57474 20.9247C6.76276 21.7725 6.97074 22.2838 7.56727 22.9139C8.6672 24.0757 9.95115 24.1037 11.5374 23.9085C14.2577 23.5737 17 19.5 17 19.5L15.5075 17.9408C15.5075 17.9408 13.5223 19.93 12.5298 19.93C11.5373 19.93 11.5374 18.9354 11.5374 18.9354L15.5075 8Z" fill="#2aa8f8"/>
  </svg>
</template>

<script>
  export default {
    name: 'InfoIcon'
  }
</script>
