<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 5H22" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 12H22" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="3" cy="12" r="2" fill="#14142B"/>
    <circle cx="3" cy="5" r="2" fill="#14142B"/>
    <circle cx="3" cy="19" r="2" fill="#14142B"/>
    <path d="M10 19H22" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'FilterIcon'
  }
</script>
