<template>
  <div>
    <v-app-bar 
      id="app-bar-mobile"
      app
      flat
      color="#ffffff"
      light
      clipped-left
      :class="{'hidden-app-bar': isHiddenApiaryButton}"
    >
      <v-select
        id="apiary-select"
        :items="inventory"
        :disabled="apiaryButton"
        v-model="apiary"
        @change="updateApiaryHives"
        item-text="name"
        item-value="id"
        label="Apiary Name"
        placeholder="Apiary Name"
        solo
        flat
        hide-details
        color="#262338"
        background-color="#f5f7fa"
        
        class="my-select-box"
        append-icon="mdi-chevron-down"
        :menu-props="{ bottom: true, offsetY: true }"
      >
        <template slot="item" slot-scope="data">
          <span class="apiary-item">{{data.item.name}}</span>
        </template>
        <template slot="selection" slot-scope="data">
          <span class="apiary-select">{{data.item.name}}</span>
        </template>
      </v-select>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'NavigationBar',
    computed: {
      ...mapGetters([
        "inventory",
        "selectedApiary",
        "latestInspectionDate",
        "apiaryButton",
        "inspectionDates",
        "apiaryHives",
        "isHiddenApiaryButton",
        "seasonalFromDate",
        "seasonalToDate",
        "chosenDateDuration"
      ]),
      apiary: {
        get() {
          return this.selectedApiary;
        },
        set(value) {
          let apiaryObj = this.inventory.find(item => item.id == value);
          this.$store.commit('selectedApiary', apiaryObj);
        }
      }
    },
    data: () => ({
      showLoader: false
    }),
    methods: {
      ...mapActions([
        "getInspectionDates",
        "getApiaryHives",
        "getUncompletedActions",
        "setLatestInspectionDate",
        "setSelectedInspectionDate",
        "setActionDetails",
        "inspectionGroupMetrics",
        "resetCharts",
        "resetSeasonalCharts",
        "seasonalGroupMetrics",
        "setSeasonalFromToDates",
        "resetSeasonalDates",
        "setCompletedTitle"
      ]),
      updateApiaryHives() {
        let page = this.$route.name;
        this.showLoader = true;
        let apiaryId = this.selectedApiary.id;

        this.getInspectionDates(apiaryId).then(() => {
          if (this.inspectionDates.length != 0) {
            let inspectionGroupId = this.selectedApiary.last_group_id;
            let latestInspection = {
              date: moment(this.selectedApiary.last_group_date).format("YYYY/MM/DD"),
              id: inspectionGroupId
            };
            this.setLatestInspectionDate(latestInspection).then(() => {
              if (page == 'hives' || page == 'edit_hives') {
                let payload = {
                  'apiaryId': apiaryId,
                  'groupId': inspectionGroupId
                };
                this.getApiaryHives(payload).then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'inspection_analysis') {
                this.getInspectionData(inspectionGroupId);
              } else if (page == 'seasonal_analysis') {
                this.getSeasonalData(inspectionGroupId);
              } else if (page == 'hive_management') {
                this.getActionsHistory();
              }
            });
            this.setSelectedInspectionDate(latestInspection.date);
          } else {
            this.setLatestInspectionDate({}).then(() => {
              if (page == 'hives' || page == 'edit_hives') {
                let payload = {
                  'apiaryId': apiaryId,
                };
                this.getApiaryHives(payload).then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'inspection_analysis') {
                this.resetCharts().then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'seasonal_analysis') {
                this.resetSeasonalCharts().then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'hive_management') {
                this.getActionsHistory();
              }
            });
            this.setSelectedInspectionDate('');
          }
        });
      },
      getInspectionData(inspectionGroupId) {
        let promises = [];
        let metrics = [
          'generalStatus',
          'queenColor',
          'populationFrames',
          'broodFrames',
          'honeycombs',
          'harvestedFrames'
        ];

        this.resetCharts().then(() => {
          for (let k = 0; k < metrics.length; k++) {
            let promise = this.getInspectionMetric(metrics[k], inspectionGroupId);
            promises.push(promise);
          }

          Promise.all(promises).then((res) => {
            this.showLoader = false;
          });
        });
      },
      getInspectionMetric(field, inspectionGroupId) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            ig_id: inspectionGroupId,
            field: field
          };
          this.inspectionGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        })
      },
      getSeasonalData(inspectionGroupId) {
        let promises = [];
        let metrics = [
          'generalStatus',
          'populationFramesS',
          'broodFramesS',
          'harvestedFramesS'
        ];

        this.resetSeasonalDates();
        this.resetSeasonalCharts().then(() => {
          this.updateSeasonalDateRange().then(() => {
            for (let k = 0; k < metrics.length; k++) {
              let promise = this.getSeasonalMetric(metrics[k]);
              promises.push(promise);
            }

            Promise.all(promises).then((res) => {
              this.showLoader = false;
            });
          });
        });
      },
      updateSeasonalDateRange() {
        return new Promise((resolve, reject) => {
          let fromDate = moment().subtract(this.chosenDateDuration, "days").format("YYYY-MM-DD");
          let toDate = moment().format("YYYY-MM-DD");

          let payload = {
            fromDate: fromDate,
            toDate: toDate
          };
          
          this.setSeasonalFromToDates(payload).then(() => {
            resolve();
          });
        });
      },
      getSeasonalMetric(field) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            date_from: this.seasonalFromDate,
            date_to: this.seasonalToDate,
            field: field
          };
          this.seasonalGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        });
      },
      getActionsHistory() {
        this.showLoader = true;
        let payload = {
          'apiaryId': this.selectedApiary.id
        };
        this.setActionDetails({});
        this.setCompletedTitle('viewCompleted');
        this.getUncompletedActions(payload).then(() => {
          this.showLoader = false;
        });
      }
    }
  };
</script>

<style type="text/css">
  #app-bar-mobile,
  #app-bar-mobile .v-toolbar__content {
    height: 58px !important;
  }

  #app-bar-mobile.hidden-app-bar {
    visibility: hidden;
    display: none;
  }

  .apiary-item {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
  }

  .apiary-select,
  #my-app .v-list-item--active .apiary-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }
</style>