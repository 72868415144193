import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import GmapVue from 'gmap-vue'
import VueGtag from "vue-gtag"

const isProd = process.env.VUE_APP_NODE_ENV == 'production';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
}

Vue.config.productionTip = false;

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCCtfQwUgbxt3JyWXdwcFEOq0av7y8pi-A',
    libraries: "places,geocoding",
    language: 'en'
  },
  installComponents: true
})
// Vue.component('GmapCluster', GmapCluster);

Vue.use(VueGtag, {
  config: {
    id: 'UA-164883833-1'
  }
}, router);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch("logout");
      if (router.currentRoute.name != 'login') {
        router.go("/login");
      }
    }
    throw error;
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
