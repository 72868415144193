import axios from "axios";

export default {
  state: {
    user: {},
    editUser: {},
    cardInfo: {},
    subscription: {},
    settingsActiveTab: 0,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  },
  getters: {
    user: state => state.user,
    editUser: state => state.editUser,
    cardInfo: state => state.cardInfo,
    subscription: state => state.subscription,
    settingsActiveTab: state => state.settingsActiveTab,
    currentPassword: state => state.currentPassword,
    newPassword: state => state.newPassword,
    confirmPassword: state => state.confirmPassword
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setEditUser(state, user) {
      state.editUser = user;
    },
    setCardInfo(state, cardInfo) {
      state.cardInfo = cardInfo;
    },
    setSubscription(state, subscription) {
      state.subscription = subscription;
    },
    setSettingsActiveTab(state, activeTab) {
      state.settingsActiveTab = activeTab;
    },
    setCurrentPassword(state, password) {
      state.currentPassword = password;
    },
    setNewPassword(state, password) {
      state.newPassword = password;
    },
    setConfirmPassword(state, password) {
      state.confirmPassword = password;
    },
    editProfile(state, updatedProfile) {
      state.user.firstname = updatedProfile.firstname;
      state.user.lastname = updatedProfile.lastname;
    },
    editUserProp(state, payload) {
      let value = payload.value;
      let prop = payload.prop;
      state.editUser[prop] = value;
    }
  },
  actions: {
    setEditUser({commit}, user) {
      return new Promise((resolve, reject) => {
        commit("setEditUser", user);
        resolve();
      });
    },
    setSettingsActiveTab({commit}, activeTab) {
      return new Promise((resolve, reject) => {
        commit("setSettingsActiveTab", activeTab);
        resolve();
      });
    },
    getUserProfile({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/profile`, {}).then((res) => {
          commit("setUser", res.data.result.user);
          resolve();
        }).catch((er) => {
          reject(er);
        })
      });
    },
    getCardInfo({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/get-card-info`, {}).then((res) => {
          commit("setCardInfo", res.data.card);
          commit("setSubscription", res.data.subscription);
          resolve();
        }).catch((er) => {
          reject(er);
        })
      });
    },
    changePassword({}, passwords) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/profile/password-change`, {
          ...passwords
        }).then(() => {
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    editProfile({
      commit
    }, profile) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/profile`, {
          ...profile
        }).then((res) => {
          const updatedProfile = {
            firstname: res.data.result.user.firstname,
            lastname: res.data.result.user.lastname,
          }
          commit("editProfile", updatedProfile)
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    resetEditUser({commit}) {
      return new Promise((resolve, reject) => {
        commit('setEditUser', {});
        commit('setCurrentPassword', '');
        commit('setNewPassword', '');
        commit('setConfirmPassword', '');
        resolve();
      })
    },
    resetPassword({}, passwords) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/change-password`, {
          ...passwords
        }).then(() => {
          resolve();
        }).catch(() => {
          reject();
        })
      })
    }
  }
}
