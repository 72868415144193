<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5H21L20.3375 18.2497C20.2045 20.9107 18.0081 23 15.3438 23H8.65624C5.99187 23 3.79554 20.9107 3.66248 18.2497L3 5Z" stroke="#14142B" stroke-width="2"/>
    <path d="M7 5V4C7 2.34315 8.34315 1 10 1H14C15.6569 1 17 2.34315 17 4V5" stroke="#14142B" stroke-width="2"/>
    <path d="M1 5H23" stroke="#14142B" stroke-width="2" stroke-linecap="round"/>
    <path d="M15 11V16" stroke="#14142B" stroke-width="2" stroke-linecap="round"/>
    <path d="M9 11V16" stroke="#14142B" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'DeleteIcon'
  }
</script>
