<template>
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.49239C1 5.38782 1.89543 4.49239 3 4.49239H23C24.1046 4.49239 25 5.38782 25 6.49239V19.4924C25 20.597 24.1046 21.4924 23 21.4924H3C1.89543 21.4924 1 20.597 1 19.4924V6.49239Z" stroke="#262338" stroke-width="2" stroke-linejoin="round"/>
    <path d="M2.42131 5.79526C1.91709 5.33306 2.24409 4.49239 2.9281 4.49239H23.0719C23.7559 4.49239 24.0829 5.33306 23.5787 5.79526L15.0272 13.6341C13.8802 14.6855 12.1198 14.6855 10.9728 13.6342L2.42131 5.79526Z" stroke="#262338" stroke-width="2" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'MailIcon'
  }
</script>
