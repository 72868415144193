import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';
import profile from './profile';
import inventory from './inventory';
import hives from './hives';
import inspections from './inspections';
import actions from './actions';
import hive_filters from './hive_filters';
import inspection_analysis from './inspection_analysis';
import seasonal_analysis from './seasonal_analysis';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    profile,
    inventory,
    hives,
    hive_filters,
    inspection_analysis,
    seasonal_analysis,
    inspections,
    actions
  }
})
