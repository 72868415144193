<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9506 18.0052C17.3783 17.1919 14.7849 16.125 12 16.125C9.21513 16.125 6.62175 17.1919 5.04942 18.0052C4.03431 18.5302 3.42295 19.5601 3.2883 20.695L3 23.125H21L20.7117 20.695C20.577 19.5601 19.9657 18.5302 18.9506 18.0052Z" stroke="#262338" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 11.625C14.4853 11.625 16.5 9.61028 16.5 7.125C16.5 4.63972 14.4853 2.625 12 2.625C9.51472 2.625 7.5 4.63972 7.5 7.125C7.5 9.61028 9.51472 11.625 12 11.625Z" stroke="#262338" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ProfileIcon'
  }
</script>
