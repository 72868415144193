import axios from "axios";
import moment from "moment";
import i18n from '@/i18n';

function getDateDifference(date) {
  let result = {
    date: '',
    icon: ''
  };

  let givenDate = moment(date, 'YYYY-MM-DD');
  let today = moment().startOf('day');
  let diffDate = today.diff(givenDate, 'days');

  if (diffDate == 0) {
    result.date = i18n.t('today');
    result.icon = 'calendar_future';
  } else if (diffDate == 1) {
    result.date = i18n.t('yesterday');
    result.icon = 'calendar_past';
  } else if (diffDate > 1) {
    result.date = i18n.t('before') + diffDate + i18n.t('days');
    result.icon = 'calendar_past';
  } else if (diffDate == -1) {
    result.date = i18n.t('tomorrow');
    result.icon = 'calendar_future';
  } else {
    result.date = i18n.t('in') + (-1*diffDate) + i18n.t('days');
    result.icon = 'calendar_future';
  }
  return result;
}

export default {
  state: {
    hiveActions: [],
    actionsModalTabs: [],
    newActionData: {},
    actionItems: [],
    actionDetails: {},
    editActionItem: {},
    completedTitle: 'viewCompleted'
  },
  getters: {
    hiveActions: state => state.hiveActions,
    actionsModalTabs: state => state.actionsModalTabs,
    newActionData: state => state.newActionData,
    actionItems: state => state.actionItems,
    actionDetails: state => state.actionDetails,
    editActionItem: state => state.editActionItem,
    completedTitle: state => state.completedTitle
  },
  mutations: {
    setHiveActions(state, hiveActions) {
      let sortedHiveActions = hiveActions.sort(
        (a, b) => moment(b.initiate_at, "YYYY/MM/DD") - moment(a.initiate_at, "YYYY/MM/DD")
      );

      sortedHiveActions.forEach((el) => {
        let diffDateObj = getDateDifference(el.initiated_at);
        el.formattedDate = diffDateObj.date;
        el.icon = diffDateObj.icon;
      });
      state.hiveActions = [];
      state.hiveActions = sortedHiveActions;
    },
    setNewActionData(state, actionData) {
      state.newActionData = {};
      state.newActionData = actionData;
    },
    setNewActionProp(state, payload) {
      state.newActionData[payload.prop] = payload.value;
    },
    setActionItems(state, actions) {
      let sortedActions = actions.sort(
        (a, b) => moment(b.initiated_at, "YYYY/MM/DD") - moment(a.initiated_at, "YYYY/MM/DD")
      );
      sortedActions.forEach((el) => {
        let diffDateObj = getDateDifference(el.initiated_at);
        el.formattedDate = diffDateObj.date;
        el.icon = diffDateObj.icon;
        let hive_numbers = [...el.hive_numbers];
        hive_numbers = hive_numbers.sort((a, b) => a.number - b.number);
        el.hive_numbers = [...hive_numbers];
      });
      state.actionItems = [];
      state.actionItems = sortedActions;
    },
    setActionDetails(state, actionData) {
      state.actionDetails = {};
      state.actionDetails = actionData;
    },
    setEditActionItem(state, actionData) {
      state.editActionItem = {};
      state.editActionItem = actionData;
    },
    setCompletedTitle(state, title) {
      state.completedTitle = title;
    },
    setHiveActionActive(state, action) {
      state.hiveActions.forEach((el) => {
        el.active = false;
      });
      let activeItem = state.hiveActions.find(item => item.id == action.id);
      activeItem.active = true;
    },
    setActionActive(state, action) {
      state.actionItems.forEach((el) => {
        el.active = false;
      });
      let activeItem = state.actionItems.find(item => item.id == action.id);
      activeItem.active = true;
    },
    setEditActionItemProp(state, payload) {
      state.editActionItem[payload.prop] = payload.value;
    },
    removeHive(state, hiveId) {
      state.editActionItem.hive_numbers = state.editActionItem.hive_numbers.filter(item => {
        return item.id != hiveId
      });
      state.editActionItem.hive_numbers.sort((a, b) => a.number - b.number);
    },
    setActiveTab(state, index) {
      for (let k = 0; k < state.actionsModalTabs.length; k++) {
        state.actionsModalTabs[k].active = false;
      }
      state.actionsModalTabs[index].active = true;
    },
    setActionsModalTabs(state, tabs) {
      state.actionsModalTabs = [];
      state.actionsModalTabs = tabs;
    },
    resetHiveActions(state) {
      state.hiveActions = [];
    }
  },
  actions: {
    getHiveActions({commit}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/api/generalAction`;
        axios({
          method: 'get',
          url: url,
          params: {
            'hive_id': payload.hiveId,
            'completed': payload.completed
          }
        }).then(res => {
          let result = [];

          if (res.data.tasks.length != 0) {
            result = res.data.tasks.filter((el) => {
              el.active = false;
              return el;
            });
          }
          commit('setHiveActions', result);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    getUncompletedActions({commit}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/api/generalAction`;
        axios({
          method: 'get',
          url: url,
          params: {
            'apiary_id': payload.apiaryId,
            'completed': 0
          }
        }).then(res => {
          let result = [];

          if (res.data.tasks.length != 0) {
            result = res.data.tasks.filter((el) => {
              el.active = false;
              return el;
            });
          }
          commit("setActionItems", result);
          resolve();
        }).catch(er => {
          reject(er);
        });
      });
    },
    getCompletedActions({commit}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/api/generalAction`;
        axios({
          method: 'get',
          url: url,
          params: {
            'apiary_id': payload.apiaryId,
            'completed': 1
          }
        }).then(res => {
          let result = [];

          if (res.data.tasks.length != 0) {
            result = res.data.tasks.filter((el) => {
              el.active = false;
              return el;
            });
          }
          commit("setActionItems", result);
          resolve();
        }).catch(er => {
          reject(er);
        });
      })
    },
    hideGeneralAction({commit}, actionId) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_URL}/api/generalActionHide/${actionId}`;
        axios.post(url, {}).then(res => {
          resolve();
        }).catch(er => {
          reject(er);
        });
      })
    },
    setActionsModalTabs({commit}, tabs) {
      return new Promise((resolve, reject) => {
        commit('setActionsModalTabs', tabs);
        resolve();
      });
    },
    setActiveTab({commit}, index) {
      return new Promise((resolve, reject) => {
        commit('setActiveTab', index);
        resolve();
      });
    },
    setNewActionData({commit}, actionData) {
      return new Promise((resolve, reject) => {
        commit('setNewActionData', actionData);
        resolve();
      });
    },
    setNewActionProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setNewActionProp', payload);
        resolve();
      });
    },
    setHiveActionActive({commit}, action) {
      return new Promise((resolve, reject) => {
        commit('setHiveActionActive', action);
        resolve();
      });
    },
    setActionActive({commit}, action) {
      return new Promise((resolve, reject) => {
        commit('setActionActive', action);
        resolve();
      });
    },
    setActionDetails({commit}, actionData) {
      return new Promise((resolve, reject) => {
        commit('setActionDetails', actionData);
        resolve();
      });
    },
    setEditActionItem({commit}, actionData) {
      return new Promise((resolve, reject) => {
        commit('setEditActionItem', actionData);
        resolve();
      });
    },
    setEditActionItemProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setEditActionItemProp', payload);
        resolve();
      });
    },
    removeHive({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('removeHive', payload);
        resolve();
      });
    },
    setCompletedTitle({commit}, title) {
      return new Promise((resolve, reject) => {
        commit('setCompletedTitle', title);
        resolve();
      });
    },
    resetHiveActions({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveActions');
        resolve();
      });
    }
  }
}
