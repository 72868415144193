import axios from "axios";

function getPercentages(array) {
  let total = array.reduce((partialSum, a) => partialSum + a.count, 0);
  let percentages = [];

  for (let k = 0; k < array.length; k++) {
    let temp = Math.round((array[k].count * 100 / total) * 100) / 100;
    percentages.push(temp);
  }
  return percentages;
}

export default {
  state: {
    category: null,
    //assessment
    inspectionGeneralStatus: [],
    inspectionQueenAge: [],
    //growth
    inspectionPopulationFrames: {},
    inspectionBroodFrames: {},
    inspectionHoneycombs: {},
    //harvest
    inspectionHarvestedFrames: {}
  },
  getters: {
    category: state => state.category,
    inspectionGeneralStatus: state => state.inspectionGeneralStatus,
    inspectionQueenAge: state => state.inspectionQueenAge,
    inspectionPopulationFrames: state => state.inspectionPopulationFrames,
    inspectionBroodFrames: state => state.inspectionBroodFrames,
    inspectionHoneycombs: state => state.inspectionHoneycombs,
    inspectionHarvestedFrames: state => state.inspectionHarvestedFrames
  },
  mutations: {
    setCategory(state, value) {
      state.category = value;
    },
    setInspectionGeneralStatus(state, metrics) {
      let chartData = [
        ['General Status', '', {type: 'string', role: 'tooltip','p': {'html': true}}]
      ];
      let percentages = getPercentages(metrics);
      let excellentHives = 0;
      let goodHives = 0;
      let totalHives = 0;

      for (let k = 0; k < metrics.length; k++) {
        let label = metrics[k].label;
        let count = metrics[k].count;
        let tooltip = '<div class="my-tooltip">'+percentages[k]+'%</div>';
        chartData.push([label, count, tooltip]);
        totalHives += count;

        if (label == 'Excellent') {
          excellentHives = count;
        } else if (label == 'Good') {
          goodHives = count;
        }
      }
      state.inspectionGeneralStatus = chartData;
    },
    setInspectionQueenAge(state, metrics) {
      state.inspectionQueenAge = metrics;
    },
    setInspectionPopulationFrames(state, metricsObj) {
      let average = metricsObj.average;
      let min = metricsObj.min;
      let max = metricsObj.max;
      let metrics = metricsObj.metrics;
      let percentages = getPercentages(metrics);

      let chartData = [
        ['Population Frames', '', {type: 'string', role: 'tooltip','p': {'html': true}}]
      ];

      for (let k = 0; k < metrics.length; k++) {
        let label = metrics[k].label;
        let count = metrics[k].count;
        let tooltip = '<div class="my-tooltip">'+percentages[k]+'%</div>';
        chartData.push([label, count, tooltip]);
      }

      let populationFramesObj = {
        average: average,
        min: min,
        max: max,
        chartData: chartData
      };

      state.inspectionPopulationFrames = populationFramesObj;
    },
    setInspectionBroodFrames(state, metricsObj) {
      let average = metricsObj.average;
      let min = metricsObj.min;
      let max = metricsObj.max;
      let chartData = [
        ['Brood Frames', '', {type: 'string', role: 'tooltip','p': {'html': true}}]
      ];


      let metrics = metricsObj.metrics;
      let percentages = getPercentages(metrics);

      for (let k = 0; k < metrics.length; k++) {
        let label = metrics[k].label + ' Avg';
        let count = metrics[k].count;
        let percentages = getPercentages(metrics);
        let tooltip = '<div class="my-tooltip">'+percentages[k]+'%</div>';
        chartData.push([label, count, tooltip]);
      }

      let broodFramesObj = {
        average: average,
        min: min,
        max: max,
        chartData: chartData
      }

      state.inspectionBroodFrames = broodFramesObj;
    },
    setInspectionHoneycombs(state, metricsObj) {
      let average = metricsObj.average;
      let min = metricsObj.min;
      let max = metricsObj.max;
      let chartData = [
        ['Honeycombs', '', {type: 'string', role: 'tooltip','p': {'html': true}}]
      ];

      let metrics = metricsObj.metrics;
      let percentages = getPercentages(metrics);

      for (let k = 0; k < metrics.length; k++) {
        let label = metrics[k].label + ' Avg';
        let count = metrics[k].count;
        let tooltip = '<div class="my-tooltip">'+percentages[k]+'%</div>';
        chartData.push([label, count, tooltip]);
      }

      let honeycombsObj = {
        average: average,
        min: min,
        max: max,
        chartData: chartData
      }

      state.inspectionHoneycombs = honeycombsObj;
    },
    setInspectionHarvestedFrames(state, metricsObj) {
      let average = metricsObj.average;
      let min = metricsObj.min;
      let max = metricsObj.max;
      let chartData = [
        ['Harvested Frames', '', {type: 'string', role: 'tooltip','p': {'html': true}}]
      ];

      let metrics = metricsObj.metrics;
      let percentages = getPercentages(metrics);

      for (let k = 0; k < metrics.length; k++) {
        let label = metrics[k].label + ' Avg';
        let count = metrics[k].count;
        let tooltip = '<div class="my-tooltip">'+percentages[k]+'%</div>';
        chartData.push([label, count, tooltip]);
      }

      let harvestedFramesObj = {
        average: average,
        min: min,
        max: max,
        chartData: chartData
      }

      state.inspectionHarvestedFrames = harvestedFramesObj;
    },
    resetCharts(state) {
      state.inspectionGeneralStatus = [];
      state.inspectionQueenAge = [];
      state.inspectionPopulationFrames = {};
      state.inspectionBroodFrames = {};
      state.inspectionHoneycombs = {};
      state.inspectionHarvestedFrames = {};
    },
    resetCategory(state) {
      state.category = null;
    }
  },
  actions: {
    inspectionGroupMetrics({state, commit, rootState}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/inspectionGroupMetrics`, {
          ...payload
        }).then(res => {
          if (res.data.result) {
            if (payload.field == 'generalStatus') {
              if (res.data.result != null) {
                commit('setInspectionGeneralStatus', res.data.result[0].metrics);
              }
            } else if (payload.field == 'queenColor') {
              if (res.data.result != null) {
                commit('setInspectionQueenAge', res.data.result.metrics);
              }
            } else if (payload.field == 'populationFrames') {
              commit('setInspectionPopulationFrames', res.data.result);
            } else if (payload.field == 'broodFrames') {
              commit('setInspectionBroodFrames', res.data.result);
            } else if (payload.field == 'honeycombs') {
              commit('setInspectionHoneycombs', res.data.result);
            } else if (payload.field == 'harvestedFrames') {
              commit('setInspectionHarvestedFrames', res.data.result);
            }
          }
          resolve();
        })
        .catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    resetCharts({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetCharts');
        resolve();
      });
    },
  },
}
