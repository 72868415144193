<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.3135L9.65685 17.9703L20.9706 6.65662" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'CheckIcon'
  }
</script>
