import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from '@/i18n.js';
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: '/hives'
  },
  {
    path: '/:lang?/login/:new?/',
    name: 'login',
    component: () => import('../views/AuthWrapper.vue'),
    props: {
      currentComponent: 'Login',
    },
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: '/:lang?/sign_up',
    name: 'sign_up',
    component: () => import('../views/AuthWrapper.vue'),
    props: {
      currentComponent: 'SignUp',
    },
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: '/:lang?/confirmed',
    name: 'confirmed',
    component: () => import('../views/AuthWrapper.vue'),
    props: {
      currentComponent: 'EmailConfirmed'
    },
    meta: {
      ifNotAuthenticated: true
    },
  },
  {
    path: '/:lang?/forgot_password',
    name: 'forgot_password',
    component: () => import('../views/AuthWrapper.vue'),
    props: {
      currentComponent: 'ForgotPassword'
    },
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: "/:lang?/password/reset/:rstToken",
    name: "reset_password",
    component: () => import('../views/AuthWrapper.vue'),
    props: {
      currentComponent: 'ResetPassword',
      props: route => ({ query: route.query.email })
    },
    meta: {
      ifNotAuthenticated: true
    },
  },
  {
    path: '/:lang?/hives',
    name: 'hives',
    component: () => import('../views/Hives.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/apiaries',
    name: 'apiaries',
    component: () => import('../views/Apiaries.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/hive_management',
    name: 'hive_management',
    component: () => import('../views/HiveManagement.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/inspection_analysis',
    name: 'inspection_analysis',
    component: () => import('../views/InspectionAnalysis.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/seasonal_analysis',
    name: 'seasonal_analysis',
    component: () => import('../views/SeasonalAnalysis.vue'),
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: '/:lang?/privacy_policy',
    name: 'privacy_policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: '/:lang?/terms_of_use',
    name: 'terms_of_use',
    component: () => import('../views/TermsOfUse.vue'),
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: '/:lang?/cookie_policy',
    name: 'cookie_policy',
    component: () => import('../views/Cookies.vue'),
    meta: {
      ifNotAuthenticated: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const hasLang = to.params && to.params.lang;
  const hadLang = from.params && from.params.lang;
  if (hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase()) {
    next();
  }
  let lang = VueI18n.locale.toLowerCase() || 'en' ;
  if (hasLang) {
    lang = to.params.lang.toLowerCase();
  }
  if (!['en', 'el', 'es'].includes(lang)) {
    lang = 'en';
  }
  VueI18n.locale = lang;
  if (!hasLang) {
    return next(`/${lang}${to.fullPath}`);
  }
  next();
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.body.classList.remove("modal-open");

  if (to.matched.some(record => record.meta.ifAuthenticated) && !store.getters.isLoggedIn || to.matched.some(record => record.meta.ifAuthenticated) && !store.getters.isLoggedIn ) {
    next({ path: 'login' });
  }
  else if (to.matched.some(record => record.meta.ifNotAuthenticated) && store.getters.isLoggedIn) {
    next({ path: 'hives' });
  }
  else {
    next();
  }
})

export default router
