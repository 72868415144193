<template>
  <v-app id="my-app">

    <template v-if="!loader">
      <div v-if="isLoggedIn && showNavBar">
        <NavigationBar
          @page-loading="showLoader = true"
          @page-loading-complete="showLoader = false"
        ></NavigationBar>
      </div>

      <v-main class="main-background" 
        :class="{'main-extension': !isHiddenApiaryButton}">
        <router-view/>
      </v-main>

      <v-overlay :value="showLoader">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </template>

    <InitialLoader v-else></InitialLoader>

    <Footer v-show="!isLoggedIn"></Footer>
  </v-app>
</template>

<script>
  import NavigationBar from '@/components/Navigation/NavigationBar';
  import NavigationBarMobile from '@/components/Navigation/NavigationBarMobile';
  import InitialLoader from "@/components/InitialLoader";
  import Footer from "@/components/Footer";
  import { mapActions, mapGetters } from "vuex";
  import moment from 'moment';

  export default {
    name: 'App',
    components: {
      NavigationBar,
      NavigationBarMobile,
      InitialLoader,
      Footer
    },
    computed: {
      ...mapGetters([
        "isLoggedIn",
        "inventory",
        "selectedApiary",
        "inspectionDates",
        "latestInspectionDate",
        "isHiddenApiaryButton"
      ]),
      showNavBar() {
        let isLoginPage = this.$route.name == 'login';
        let isRegisterPage = this.$route.name == 'sign_up';
        let isForgotPasswordPage = this.$route.name == 'forgot_password';
        return !isLoginPage && !isRegisterPage && !isForgotPasswordPage;
      }
    },
    data() {
      return {
        loader: false,
        showLoader: false
      };
    },
    created() {
      console.log('app created');
      let queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let token = urlParams.get('token');

      if (token != null && !this.isLoggedIn) {
        this.loginUserExt(token).then((res) => {
          if (this.isLoggedIn) {
            this.$router.go("hives");
          }
        }).catch((er) => {
          console.log(er);
        });
      } else if (this.isLoggedIn) {
        this.loader = true;
        this.getUserProfile();
        this.checkSubscription();
        this.getInventory().then(() => {
          console.log('getInventory');
          if (this.inventory.length != 0) {
            this.updateSelectedApiary(this.inventory[0]).then(() => {
              this.getInspectionDates(this.selectedApiary.id).then(() => {
                if (this.inspectionDates.length != 0) {
                  let latestInspection = {
                    date: moment(this.selectedApiary.last_group_date).format("YYYY/MM/DD"),
                    id: this.selectedApiary.last_group_id
                  };
                  this.setLatestInspectionDate(latestInspection).then(() => {
                    let payload = {
                      'apiaryId': this.selectedApiary.id,
                      'groupId': latestInspection.id
                    };
                    this.getApiaryHives(payload).then(() => {
                      this.loader = false;
                    }).catch((er) => {
                      this.loader = false;
                    });
                  });
                  this.setSelectedInspectionDate(latestInspection.date);
                } else {
                  this.setLatestInspectionDate({}).then(() => {
                    let payload = {
                      'apiaryId': this.selectedApiary.id
                    };
                    this.getApiaryHives(payload).then(() => {
                      this.loader = false;
                    });
                  });
                  this.setSelectedInspectionDate('');
                }
              });
            });
          } else {
            this.loader = false;
          }
        }).catch((er) => {
          this.loader = false;
        });
      }
    },
    methods: {
      ...mapActions([
        "getInventory",
        "getInspectionDates",
        "getApiaryHives",
        "getUserProfile",
        "checkSubscription",
        "setLatestInspectionDate",
        "setSelectedInspectionDate",
        "updateSelectedApiary",
        "loginUserExt",
        "logout"
      ]),
    }
  };
</script>

<style type="text/css">
  /* Colors
  grey-text: #9295ad
  yellow-button: #eaac30
  red-button: #fd6150


  --- hive & status colors
  grey: #d9dbe9 (not inspected hive)
  red: #fd6150
  green: #03cfbb
  yellow: #ffdf9a
  blue: #2aa8f8
  */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

  body {
    height: 100vh;
  }


  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #d9dbe9;
    border-radius: 2px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9295ad;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6e7291;
    border-radius: 2px;
  }
  /* End of Custom Scrollbar */

  /* Main Content */
  #my-app, h1, h2, h3 {
    font-family: 'Poppins',sans-serif;
    color: #262338;
  }

  #my-app .v-main__wrap {
    max-width: 1600px;
    margin: 0 auto;
  }

  #my-app .v-main {
    padding: 70px 0 0 !important;
  }

  .main-background {
    background-color: #f5f7fa;
  }
  /* End of Main Content Styling */

  /* Vuetify overwrites */
  #my-app .v-btn--outlined {
    border-width: 2px;
  }

  #right-menu label, #apiary-select, 
  .my-card .select-container label {
    color:  #262338;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  #right-menu .v-select__selection--comma {
    color:  #262338;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  #my-app .v-icon.mdi-chevron-down.theme--light {
    color: #262338;
  }

  #my-app .v-menu__content {
    border-radius: 12px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
  }

  #my-app .v-list-item.v-list-item--active::before {
    opacity: 0;
  }

  #my-app .v-list-item.v-list-item--disabled {
    opacity: 0.8;
  }

  #my-app .my-textarea > .v-input__control > .v-input__slot {
    padding: 20px;
  }

  .my-textarea > .v-input__control > .v-input__slot::before,
  .my-textarea > .v-input__control > .v-input__slot::after {
    border: none;
  }

  #apiaries-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  #inspections-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  #actions-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  #hives-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px;
    font-weight: 500;
    color: #4E4B66;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .my-table.v-data-table > .v-data-table__wrapper > table > .v-data-table-header {
    background: #F7F7FC;
  }

  #my-app .my-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  #user-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  #billing-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  #apiaries-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  #hives-table.my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  .my-card .my-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #EFF0F7;
  }

  .my-card .v-slider--horizontal .v-slider__track-background,
  .my-card .v-slider--horizontal .v-slider__track-fill {
    border-radius: 10px;
  }

  .my-card .theme--light.v-slider .v-slider__thumb {
    border: 2px solid black !important;
    width: 14px;
    height: 14px;
  }

  .my-card .v-slider--horizontal .v-slider__thumb-label {
    color: black;
    font-weight: 500;
    background-color: #d9dbe9 !important;
  }

  .my-card .v-slider__thumb-label {
    border-radius: 50% 50% 30% !important;
  }

  .my-card .v-slider--horizontal .v-slider__thumb-label {
    transform: translateY(-20%) translateY(-8px) translateX(-50%) rotate(45deg) !important;
  }

  #my-app .v-data-footer__select {
    display: none;
  }

  #my-app .v-data-footer__pagination {
    margin: 0 10px 0 24px;
  }

  #my-app .v-data-footer {
    padding-top: 30px;
  }

  #my-app .v-data-footer .v-data-footer__pagination {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  #my-app .v-data-footer .v-data-footer__icons-before button.v-btn.v-btn--icon,
  #my-app .v-data-footer .v-data-footer__icons-after button.v-btn.v-btn--icon {
    color: #262338;
  }

  #my-app .v-data-footer .v-data-footer__icons-before button.v-btn--disabled .v-icon,
  #my-app .v-data-footer .v-data-footer__icons-after button.v-btn--disabled .v-icon {
    color: #A0A3BD !important;
  }

  #my-app .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none;
  }
  /* End of Vuetify overwrites */

  /* My Elements */
  #my-app .my-card {
    padding: 35px 40px;
    border-radius: 18px;
  }

  #my-app .my-btn {
    border-radius: 12px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 28px;
    padding: 16px 24px;
  }

  #my-app .hide-element {
    visibility: hidden;
    display: none;
  }

  .dialog-container {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    padding: 36px;
  }

  .dialog-container-sm {
    position: absolute;
    width: 40%;
    top: 15%;
    left: 30%;
  }

  .dialog-container-center {
    position: absolute;
    width: 60%;
    top: 0;
    left: 20%;
    padding: 30px 0;
  }

  .opened-dialog {
    display: block;
  }

  .modal-open {
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .modal-open::-webkit-scrollbar {
    display: none;
  }

  .closed-dialog {
    display: none;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .tab-content {
    padding: 30px;
  }

  #my-app .hive-box-container {
    flex-basis: 10%;
    flex-grow: 0;
    padding: 5px;
  }

  .hive-box {
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    width: 100%;
    min-width: 65px;
  }

  .hive-box:hover {
    box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  }

  .hive-box:before {
    content: "";
    display: block;
    padding-top: 34%;
  }

  .hive-box:after {
    content: "";
    display: block;
    padding-top: 34%;
  }

  .my-select-box,
  .my-text-field {
    border-radius: 12px;
  }

  .my-select-box.v-input--is-readonly {
    opacity: 0.5;
  }

  .my-select-box.v-input--is-readonly .v-input__slot:hover {
    cursor: not-allowed;
  }

  .my-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: rgba(0,0,0,0.87);
  }

  .my-label {
    font-size: 13px;
    font-weight: 700;
    color: #262338;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .my-list-item {
    font-size: 13px;
    font-weight: 600;
    color: #262338;
    line-height: 22px;
    letter-spacing: 0.25px;
    padding-left: 10px;
  }

  .my-textarea {
    border-radius: 16px;
  }

  .hover-action:hover {
    cursor: pointer;
  }

  #apiary-page .apiary-tabs .my-tab,
  #settings-page .settings-tabs .my-tab,
  #filter-card.my-card .my-tab,
  #hive-details-card.my-card .my-tab,
  #inspections-card.my-card .my-tab,
  #actions-card.my-card .my-tab,
  #edit-action-card.my-card .my-tab {
    justify-content: flex-start;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #A0A3BD;
    border-bottom: 3px solid #D9DBE9;
    min-width: 75px;
  }

  #apiary-page .apiary-tabs .my-tab.active-tab,
  #settings-page .settings-tabs .my-tab.active-tab,
  #filter-card.my-card .my-tab.active-tab,
  #hive-details-card.my-card .my-tab.active-tab,
  #inspections-card.my-card .my-tab.active-tab,
  #actions-card.my-card .my-tab.active-tab,
  #edit-action-card.my-card .my-tab.active-tab {
    border-bottom: 3px solid #262338;
    color: #262338;
  }

  .my-card .v-tabs.filter-tabs {
    margin: 30px -40px 0;
    width: auto;
  }

  .my-card .v-tabs.hive-details-tabs,
  .my-card .v-tabs.inspections-tabs {
    margin: 30px -40px 0;
    width: auto;
  }

  .my-card .tab-icon,
  #settings-page .settings-tabs .tab-icon,
  #apiary-page .apiary-tabs .tab-icon {
    flex: 0 0 auto;
    margin: 0 20px;
  }

  .my-card .my-select-box {
    padding-top: 20px;
  }

  .my-card .my-select-box .v-label,
  .my-card .my-select-box input {
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  #my-app .my-card button.reset-btn {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    padding: 15px 24px;
    border: 2px solid #EAAC30;
    color: #EAAC30;
  }

  #my-app .my-card button.reset-btn .v-icon {
    color: #EAAC30;
  }

  #my-app .my-card button.red-btn {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    padding: 15px 24px;
    border: 2px solid #FD6150;
    color: #FD6150;
  }

  #my-app .my-card button.red-btn.red-btn-sm {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    padding: 9px 22px;
  }

  #my-app .my-card button.red-btn .v-icon {
    color: #FD6150;
  }

  #my-app .my-card button.red-btn.red-btn-sm .v-icon {
    font-size: 16px;
  }

  #my-app .my-card button.reset-btn:hover {
    background-color: #EAAC30;
    color: #ffffff;
  }

  #my-app .my-card button.red-btn:hover {
    background-color: #FD6150;
    color: #ffffff;
  }

  #my-app .my-card button.reset-btn:hover .v-icon,
  #my-app .my-card button.red-btn:hover .v-icon {
    color: #ffffff;
  }

  #my-app .my-card button.reset-btn:hover svg path,
  #my-app .my-card button.red-btn:hover svg path {
    stroke: #ffffff;
  }

  #my-app .my-card button.red-btn:hover::before,
  #my-app .my-card button.reset-btn:hover::before,
  #my-app .my-card button.save-btn:hover::before {
    opacity: 0;
  }

  #my-app .my-card-actions {
    padding: 0;
  }

  #my-app .my-card .my-card-actions button.save-btn {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    padding: 15px 24px;
    border: 2px solid #03CFBB;
    background-color: #03CFBB;
    color: #ffffff;
  }

  #my-app .my-card .my-card-actions button.save-btn.save-btn-sm {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    padding: 9px 22px;
  }

  #my-app .my-card button.save-btn .v-icon {
    color: #ffffff;
  }

  #my-app .my-card button.save-btn.save-btn-sm .v-icon {
    font-size: 16px;
  }

  #my-app .my-card button.save-btn:hover {
    background-color: #ffffff;
    color: #03CFBB;
  }

  #my-app .my-card button.save-btn:hover .v-icon {
    color: #03CFBB;
  }

  #my-app .my-card button.save-btn:hover svg path {
    stroke: #03CFBB;
  }

  #my-app .my-btn.save-btn.v-btn--disabled,
  #my-app .my-btn.save-btn.v-btn--disabled .v-icon {
    color: #03CFBB !important;
  }

  #my-app .my-btn.save-btn.v-btn--disabled svg path {
    stroke: #03CFBB;
  }

  .my-card .v-slider--horizontal .v-slider__track-container {
    height: 8px;
  }

  .my-checkbox-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
    margin: 16px 10px 0 0;
    padding-top: 4px;
    cursor: pointer;
  }

  #my-app .vue-map {
    border-radius: 12px;
  }

  .my-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    overflow-y: scroll;
    z-index: 10;
  }

  .inspections-title-icon {
    padding-right: 10px;
  }

  .inspections-subtitle {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  /*.inspections-actions {
    margin-top: 50px;
  }*/

  .selected-hives-label {
    position: absolute;
    background-color: #eaac30;
    padding: 10px 20px;
    height: 50px;
    top: 38px;
    right: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.75px;
  }

  #my-app .my-textarea .v-label {
    top: 4px;
    font-weight: 600;
    color: #6E7191;
    letter-spacing: 0.25;
  }

  .my-textarea > .v-input__control > .v-input__slot > .v-text-field__slot textarea {
    margin-top: 18px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #14142B;
  }

  .label-padding {
    padding-bottom: 20px;
  }

  .plus-minus-btn.v-btn--fab.v-size--default .v-icon {
    font-size: 16px;
  }

  #my-app .plus-minus-btn.v-btn--disabled {
    background-color: #EFF0F7 !important;
    color: #262338 !important;
    opacity: 0.5;
  }

  #my-app .plus-minus-btn.v-btn--disabled .v-icon {
    color: #262338 !important;
  }

  .increment-step {
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.75px;
  }

  p.increment-step {
    margin: 0;
  }

  .dialog-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    overflow-y: scroll;
    z-index: 12;
  }

  .warning-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #FD6150;
  }

  .warning-content {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }
  /* End of My Elements */

  /* Datepicker customization */
  #my-app .datepicker-container {
    width: 100%;
  }

  #my-app .datepicker-container.disabled {
    opacity: 0.8;
  }

  #my-app .datepicker-btn {
    padding: 16px 24px;
    border: 2px solid #EAAC30;
    border-radius: 12px;
    font-weight: 600;
    font-size: calc(13px + (15 - 13) * ((100vw - 375px) / (1440 - 375)));
    line-height: 24px;
    width: 100%;
  }

  #my-app .datepicker-btn.datepicker-btn-sm {
    padding: 12px 24px;
  }

  #my-app .datepicker-btn:hover {
    border-color: #262338;
  }

  #my-app .datepicker-container.disabled .datepicker-btn:hover {
    border-color: #EAAC30;
    cursor: not-allowed;
  }

  #my-app .datepicker-container .mx-icon-calendar {
    color: #ffffff;
    font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1440 - 375)));
    right: 24px;
  }

  .my-datepicker-popup {
    border-radius: 12px;
    border: transparent;
    box-shadow: 0px 0px 72px rgb(0 0 0 / 4%);
    width: 360px;
    padding: 24px;
  }

  .my-datepicker-popup .mx-calendar {
    width: 100%;
    padding: 0;
  }

  .my-datepicker-popup .mx-calendar-content {
    height: unset;
  }

  .my-datepicker-popup .mx-calendar-header-label {
    font-size: 20px;
    line-height: 32px;
  }

  .my-datepicker-popup .mx-calendar-header .mx-btn {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #262338;
    position: relative;
  }

  .my-datepicker-popup .mx-calendar-header button.mx-btn.mx-btn-text.mx-btn-current-month {
    margin-right: 10px;
  }

  .my-datepicker-popup .mx-calendar-header button.mx-btn.mx-btn-text.mx-btn-current-month::after {
    width: 16px;
    height: 10px;
    content:"";
    display: inline-block;
    background: url("./assets/arrow_caret_down.svg") no-repeat left top;
  }

  .my-datepicker-popup .mx-calendar-header .mx-icon-left::before,
  .my-datepicker-popup .mx-calendar-header .mx-icon-double-left::before,
  .my-datepicker-popup .mx-calendar-header .mx-icon-double-left::after,
  .my-datepicker-popup .mx-calendar-header .mx-icon-right::before,
  .my-datepicker-popup .mx-calendar-header .mx-icon-double-right::before,
  .my-datepicker-popup .mx-calendar-header .mx-icon-double-right::after {
    width: 14px;
    height: 14px;
    border-width: 4px 0 0 4px;
    border-radius: 2px;
  }

  .my-datepicker-popup .mx-table th {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #A0A3BD;
    padding: 20px 5px 5px;
  }

  .my-datepicker-popup .mx-table .cell {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color:  #262338;
    height: unset;
    padding: 10px 5px;
    border: 2px solid transparent;
  }

  .my-datepicker-popup .mx-calendar-content .cell:hover {
    background:  #eff0f6;
    border-radius: 50px;
  }

  .my-datepicker-popup .mx-table .cell.disabled {
    opacity: 0.5;
    background: none;
  }

  .my-datepicker-popup .mx-table .cell.active {
    background: #D9DBE9;
    border-radius: 50px;
    color: #262338;
  }

  .my-datepicker-popup .mx-table .cell.highlight,
  .my-datepicker-popup .mx-table .cell.highlight:hover {
    color: #EAAC30;
  }
  /* End of datepicker customization */

  /* Start of Custom Switch */
  .switch-label {
    padding-right: 15px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D9DBE9;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #03cfbb;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  .slider.round {
    border-radius: 40px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  /* End of Custom Switch */
  #my-app .my-tooltip {
    width: 70px;
    height: 28px;
    background: #262338;
    border-radius: 12px;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    text-align: center;
    padding: 0;
    opacity: 1 !important;
    transition: none !important;
  }

  #my-app .google-visualization-tooltip {
    border: none;
    box-shadow: none;
    background: transparent;
  }

  #my-app .productivity-tooltip {
    background: #262338;
    border-radius: 12px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    width: 400px;
    height: auto;
  }

  .info-icon {
    background: #ffffff;
    border: 2px solid #2aa8f8;
    border-radius: 50px;
    padding: 2px 5px;
    position: absolute;
    top: -30px;
    right: 122px;
    cursor: pointer;
    font-size: 14px;
  }

  .info-icon .v-icon__component {
    width: 14px;
    height: 14px;
  }

  @media screen and (max-width: 1900px) {
    .dialog-container {
      left: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 1264px) {
    .dialog-container-center {
      width: 80%;
      left: 10%;
    }

    .dialog-container-sm {
      width: 50%;
      left: 25%;
    }
  }

  @media screen and (max-width: 960px) {
    #filter-card.my-card .my-checkbox .v-input--selection-controls__input {
      margin: 5px;
      width: 20px;
    }

    #my-app .dialog-container {
      padding: 0;
    }

    #filter-card.my-card,
    #hive-details-card.my-card,
    #inspections-card.my-card,
    #actions-card.my-card,
    #edit-action-card.my-card {
      border-radius: 0;
    }

    #my-app .hive-box-container {
      padding: 7px;
    }

    .selected-hives-label {
      padding: 10px 15px;
      letter-spacing: 0.25px;
      top: 30px;
    }

    .dialog-container-center {
      width: 90%;
      left: 5%;
    }

    .dialog-container-sm {
      width: 70%;
      left: 15%;
    }
  }

  @media screen and (max-width: 600px) {
    #my-app .v-main.main-extension {
      padding: 128px 0 0 !important;
    }

    #my-app .my-btn {
      padding: 11px 22px;
    }

    #my-app .datepicker-btn,
    #hives-page .datepicker-btn {
      padding: 11px 16px;
    }

    #my-app .my-card {
      padding: 20px;
    }

    .my-card .v-tabs.hive-details-tabs {
      margin: 40px -20px 0;
    }

    #filter-card.my-card .my-tab,
    #hive-details-card.my-card .my-tab,
    #inspections-card.my-card .my-tab,
    #actions-card.my-card .my-tab,
    #edit-action-card.my-card .my-tab {
      padding: 0;
    }

    .my-card .tab-icon,
    #apiary-page .apiary-tabs .tab-icon {
      margin: 0 10px;
    }

    .my-card .v-tabs.inspections-tabs {
      margin: 0 -40px;
    }

    .selected-hives-label {
      position: static;
      border-radius: 0;
      margin: 30px -25px 0;
    }

    .my-btn-padding {
      padding: 5px;
    }

    #my-app .hive-box-container {
      padding: 5px;
    }

    .dialog-container-sm {
      width: 90%;
      left: 5%;
    }

    #my-app .hide-element-sm {
      visibility: none;
      display: none;
    }

    .info-icon {
      padding: 2px 4px;
      top: -32px;
      right: 100px;
    }

    #my-app .productivity-tooltip {
      width: 300px;
    }

  }

  @media screen and (max-width: 375px) {
    #my-app .hide-element-xs {
      visibility: none;
      display: none;
    }
  }
</style>