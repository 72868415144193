import axios from "axios";
import router from "@/router";
import moment from "moment";

function getSubscriptionFlags(sub_status) {
  let result = {
    subscriptionActive: false,
    subscriptionMissing: false,
    subscriptionError: false,
    subscriptionNoNeed: false,
  }

  if (sub_status.status_code == 0) {
    // Active subscription
    result.subscriptionActive = true;
    result.subscriptionMissing = false;
    result.subscriptionError = false;
    result.subscriptionNoNeed = false;
  } else if (sub_status.status_code == 2 || sub_status.status_code == 7) {
    // 2:Missing subscription, 7:canceled subscription
    result.subscriptionActive = false;
    result.subscriptionMissing = true;
    result.subscriptionError = false;
    result.subscriptionNoNeed = false;
  } else if (sub_status.status_code == 12) {
    // No need subscription
    result.subscriptionActive = false;
    result.subscriptionMissing = false;
    result.subscriptionError = false;
    result.subscriptionNoNeed = true;
  } else {
    // Any other case
    result.subscriptionActive = false;
    result.subscriptionMissing = false;
    result.subscriptionError = true;
    result.subscriptionNoNeed = false;
  }
  return result;
}

export default {
  state: {
    token: localStorage.getItem('token') || '',
    termsAccepted: localStorage.getItem('terms') || false,
    emailVerified: localStorage.getItem('emailV') || false,
    isSubscriptionActive: localStorage.getItem('sub_active') == 'true' ? true : false || false,
    isSubscriptionMissing: localStorage.getItem('sub_missing') == 'true' ? true : false || false,
    isSubscriptionError: false,
    isSubscriptionNoNeed: localStorage.getItem('no_need_sub') == 'true' ? true : false || false,
    subStatus: {}
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.token && state.termsAccepted && state.emailVerified;
    },
    termsAccepted: state => state.termsAccepted,
    emailVerified: state => state.emailVerified,
    isSubscriptionActive: state => state.isSubscriptionActive,
    isSubscriptionMissing: state => state.isSubscriptionMissing,
    isSubscriptionError: state => state.isSubscriptionError,
    isSubscriptionNoNeed: state => state.isSubscriptionNoNeed,
    subStatus: state => state.subStatus
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
    },
    setTerms(state,val) {
      state.termsAccepted = val;
      localStorage.setItem("terms",val);
    },
    setEmailVerified(state,val) {
      state.emailVerified = val;
      localStorage.setItem("emailV",val);
    },
    setIsSubscriptionActive(state,val) {
      state.isSubscriptionActive = val;
      localStorage.setItem("sub_active",val);
    },
    setIsSubscriptionMissing(state,val) {
      state.isSubscriptionMissing = val;
      localStorage.setItem("sub_missing", val);
    },
    setIsSubscriptionError(state,val) {
      state.isSubscriptionError = val;
    },
    setIsSubscriptionNoNeed(state,val) {
      state.isSubscriptionNoNeed = val;
      localStorage.setItem("no_need_sub",val);
    },
    setSubStatus(state,val) {
      state.subStatus = val;
    },
    logout(state) {
      state.token = "";
      state.user = null;
      state.termsAccepted = false;
      state.emailVerified = false;
      state.isSubscriptionActive = false;
      state.isSubscriptionMissing = false;
      state.isSubscriptionError = false;
      state.subStatus = {};
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  actions: {
    login({ commit, dispatch,state}, user) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/auth/jwt/login`, {
          ...user
        }).then(res => {
          const token = res.data.result.token;
          commit("setToken", token);

          const terms = res.data.result.hasAcceptedTerms;
          if (terms == 1) {
            commit("setTerms", true);
          } else {
            commit("setTerms", false);
          }

          const emailV = res.data.result.emailVerified || false;
          if (emailV == 1) {
            commit("setEmailVerified", true);
          } else {
            commit("setEmailVerified", false);
          }

          const sub_status = res.data.result.sub_status || false;
          let subscriptionFlags = getSubscriptionFlags(sub_status);
          commit("setIsSubscriptionActive", subscriptionFlags.subscriptionActive);
          commit("setIsSubscriptionMissing", subscriptionFlags.subscriptionMissing);
          commit("setIsSubscriptionError", subscriptionFlags.subscriptionError);
          commit("setIsSubscriptionNoNeed", subscriptionFlags.subscriptionNoNeed);
          resolve(token);
        }).catch(err => {
          commit('logout');
          reject(err);
        });
      });
    },

    loginUserExt({commit}, token) {
      return new Promise((resolve, reject) => {
        commit("setToken", token);
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/profile`, {}).then((res) => {
          const terms = res.data.result.user.terms_accepted;
          if (terms == 1) {
            commit("setTerms", true);
          } else {
            commit("setTerms", false);
          }

          const emailV = res.data.result.user.emailVerified || false;
          if (emailV == 1) {
            commit("setEmailVerified", true);
          } else {
            commit("setEmailVerified", false);
          }

          const sub_status = res.data.result.user.sub_status || false;
          let subscriptionFlags = getSubscriptionFlags(sub_status);
          commit("setIsSubscriptionActive", subscriptionFlags.subscriptionActive);
          commit("setIsSubscriptionMissing", subscriptionFlags.subscriptionMissing);
          commit("setIsSubscriptionError", subscriptionFlags.subscriptionError);
          commit("setIsSubscriptionNoNeed", subscriptionFlags.subscriptionNoNeed);
          resolve();
        }).catch((er) => {
          reject(er);
        })
      });
    },

    checkSubscription({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/check-sub`, {}).then((res) => {
          const sub_status = res.data.sub_status || false;
          let subscriptionFlags = getSubscriptionFlags(sub_status);
          commit("setIsSubscriptionActive", subscriptionFlags.subscriptionActive);
          commit("setIsSubscriptionMissing", subscriptionFlags.subscriptionMissing);
          commit("setIsSubscriptionError", subscriptionFlags.subscriptionError);
          commit("setIsSubscriptionNoNeed", subscriptionFlags.subscriptionNoNeed);
          resolve();
        }).catch((er) => {
          reject(er);
        })
      });
    },

    logout({ commit }) {
      commit("logout");
      commit("clearInventory");
    },

    setSubStatus({commit}, subStatus) {
      return new Promise((resolve, reject) => {
        commit("setSubStatus", subStatus);
        resolve();
      });
    },

    forgotPassword({ }, email) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/accounts/v1/forgot-password`, {
          email: email
        }).then(() => {
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    
    register({ }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/register`, {
          ...payload
        }).then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
      })
    },

    acceptTerms({commit}) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/acceptTerms`
        ).then(() => {
          commit("setTerms", true);
          resolve();
        }).catch(() => {
          reject();
        });
      });
    }
  }
}