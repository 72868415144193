<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="2" transform="rotate(-90 16 16)" fill="#262338"/>
    <circle cx="6" cy="16" r="2" transform="rotate(-90 6 16)" fill="#262338"/>
    <circle cx="26" cy="16" r="2" transform="rotate(-90 26 16)" fill="#262338"/>
  </svg>
</template>

<script>
  export default {
    name: 'MoreIcon'
  }
</script>
