<template>
  <v-footer id="my-footer" color="#f5f7fa">
    <v-row
      justify="center"
      no-gutters
    >
      <v-col class="footer-links">
        <a @click="$router.push({path: 'terms_of_use'})"
          class="mr-2 text-capitalize"
        >
          {{ $t("termsOfUse") }}
        </a>
        <a @click="$router.push({path: 'privacy_policy'})" class="mr-2">
          {{ $t("privacyPolicy") }}
        </a>
        <a @click="$router.push({path: 'cookie_policy'})">
          {{ $t("cookiePol") }}
        </a>
      </v-col>
      <v-col
        class="powered-by"
        cols="12"
      >
        Powered by 
        <a href="https://codefactory.gr/">CodeFactory</a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer'
  };
</script>

<style lang="css" scoped>
  .powered-by {
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
    margin-top: 20px;
  }

  #my-footer .powered-by a,
  #my-footer .footer-links a {
    text-decoration: none;
    font-weight: 600;
    color: #262338 !important;
  }

  .footer-links {
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }
</style>
