<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3.75C3 2.50736 4.00736 1.5 5.25 1.5H6.06579C7.86072 1.5 9.31579 2.95507 9.31579 4.75V5.25C9.31579 6.49264 10.3231 7.5 11.5658 7.5H11.75C13.5449 7.5 15 8.95508 15 10.75V14.25C15 15.4926 13.9926 16.5 12.75 16.5H5.25C4.00736 16.5 3 15.4926 3 14.25V3.75Z" stroke="#6E7191" stroke-width="1.5"/>
    <path d="M3 3.75C3 2.50736 4.00736 1.5 5.25 1.5H5.87867C8.31223 1.5 10.6216 2.57442 12.1892 4.43588L13.0605 5.47059C14.3131 6.95803 15 8.84012 15 10.7847V14.25C15 15.4926 13.9926 16.5 12.75 16.5H5.25C4.00736 16.5 3 15.4926 3 14.25V3.75Z" stroke="#6E7191" stroke-width="1.5"/>
  </svg>
</template>

<script>
  export default {
    name: 'FileIcon'
  }
</script>
