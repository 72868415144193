import axios from "axios";
import moment from "moment";

export default {
  state: {
    inspectionDates: [],
    latestInspectionDate: {},
    selectedInspectionDate: '',
    selectedInspectionDateObj: {},
    hiveInspections: [],
    newInspectionActiveTab: 0,
    newInspectionDate: '',
    newInspectionData: {
      generalStatus: '',
      queenAge: '',
      broodFrames: null,
      populationFrames: null,
      harvestedFrames: null,
      honeycombs: null
    }
  },
  getters: {
    inspectionDates: state => state.inspectionDates,
    latestInspectionDate: state => state.latestInspectionDate,
    selectedInspectionDate: state => state.selectedInspectionDate,
    selectedInspectionDateObj: state => state.selectedInspectionDateObj,
    hiveInspections: state => state.hiveInspections,
    newInspectionDate: state => state.newInspectionDate,
    newInspectionData: state => state.newInspectionData
  },
  mutations: {
    setLatestInspectionDate(state, dateObj) {
      state.latestInspectionDate = dateObj;
    },
    setSelectedInspectionDate(state, date) {
      state.selectedInspectionDate = date;
    },
    setSelectedInspectionDateObj(state, date) {
      let matchedInspection = [];
      let result = {};
      
      if (state.inspectionDates.length != 0) {
        matchedInspection = state.inspectionDates.find(el => el.date == date);
      }

      if (matchedInspection.length != 0) {
        result = matchedInspection;
      }
      state.selectedInspectionDateObj = result;
    },
    setHiveInspections(state, hiveObj) {
      state.hiveInspections = hiveObj
        .sort((a, b) => moment(b.dateCreated, "YYYY/MM/DD") - moment(a.dateCreated, "YYYY/MM/DD"));
    },
    setInspectionDates(state, inspectionDates) {
      state.inspectionDates = inspectionDates
        .sort((a, b) => moment(b.date, "YYYY/MM/DD") - moment(a.date, "YYYY/MM/DD"))
        .map(inspectionDate => {
          return {
            id: inspectionDate.id,
            date: moment(inspectionDate.initiate_at).format("YYYY/MM/DD"),
            count: inspectionDate.count
          }
        })
    },
    setNewInspectionDate(state, date) {
      state.newInspectionDate = date;
    },
    setNewInspectionData(state, actionData) {
      state.newInspectionData = {};
      state.newInspectionData = actionData;
    },
    setNewInspectionProp(state, payload) {
      state.newInspectionData[payload.prop] = payload.value;
    },
    resetNewInspectionData(state) {
      state.newInspectionData = {
        generalStatus: '',
        queenAge: '',
        broodFrames: null,
        populationFrames: null,
        harvestedFrames: null,
        honeycombs: null
      };
      state.newInspectionDate = '';
    },
    resetHiveInspections(state) {
      state.hiveInspections = [];
    }
  },
  actions: {
    getHiveInspections({commit}, hiveId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/hive/${hiveId}/inspections`, {})
        .then(res => {
          commit('setHiveInspections', res.data.result.inspections);
          resolve();
        })
        .catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    getInspectionDates({commit}, apiaryId) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaryInspectionGroups`, {
          apiaryId
        }).then(res => {
          let inspections = res.data.result.inspections;
          commit('setInspectionDates', inspections);
          resolve();
        }).catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    setLatestInspectionDate({commit}, dateObj) {
      return new Promise((resolve, reject) => {
        commit('setLatestInspectionDate', dateObj);
        resolve();
      });
    },
    setSelectedInspectionDate({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setSelectedInspectionDate', date);
        commit('setSelectedInspectionDateObj', date);
        resolve();
      });
    },
    setHiveInspections({commit}, hiveObj) {
      return new Promise((resolve, reject) => {
        commit('setHiveInspections', hiveObj);
        resolve();
      });
    },
    setNewInspectionDate({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setNewInspectionDate', date);
        resolve();
      });
    },
    setNewInspectionData({commit}, inspectionData) {
      return new Promise((resolve, reject) => {
        commit('setNewInspectionData', inspectionData);
        resolve();
      });
    },
    setNewInspectionProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setNewInspectionProp', payload);
        resolve();
      });
    },
    resetNewInspectionData({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetNewInspectionData');
        resolve();
      });
    },
    resetHiveInspections({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveInspections');
        resolve();
      });
    }
  }
}
