import axios from "axios";
import i18n from '@/i18n';

export default {
  state: {
    inventory: [],
    apiariesWithRatio: [],
    selectedApiary: {},
    editApiary: null,
    apiaryButton: false,
    isHiddenApiaryButton: false
  },
  getters: {
    inventory: state => state.inventory,
    apiariesWithRatio: state => state.apiariesWithRatio,
    selectedApiary: state => state.selectedApiary,
    editApiary: state => state.editApiary,
    apiaryButton: state => state.apiaryButton,
    isHiddenApiaryButton: state => state.isHiddenApiaryButton
  },
  mutations: {
    selectedApiary(state, selectedApiaryObj) {
      state.selectedApiary = {};
      state.selectedApiary = selectedApiaryObj;
    },
    setEditApiary(state, apiary) {
      state.editApiary = {};
      state.editApiary = apiary;
    },
    setEditApiaryProp(state, payload) {
      let prop = payload.prop;
      let value = payload.value;

      state.editApiary[prop] = value;
    },
    setApiariesWithRatio(state, apiaries) {
      state.apiariesWithRatio = apiaries;
    },
    setInventory(state, inventory) {
      state.inventory = inventory;
      inventory = inventory.map(apiary => {
        let apiaryFunctionViewValue = i18n.t('apiaries.functions').find(f => f.value == apiary.apiaryFunction)?.viewValue;
        apiary.apiaryFunctionViewValue = apiaryFunctionViewValue || "";

        return {
          ...apiary,
        };
      });
    },
    clearInventory(state) {
      state.inventory = [];
    },
    updateApiary(state, apiary) {
      let apiaryFunctionViewValue = i18n.t('apiaries.functions').find(f => f.value == apiary.apiaryFunction)?.viewValue;
      let found = state.inventory.find(apr => apr.id == apiary.id);

      Object.assign(found, {
        ...apiary,
        apiaryFunctionViewValue
      });
    },
    deleteApiary(state, apiary) {
      const found = state.inventory.findIndex(apr => apr.id == apiary.id);
      state.inventory.splice(found, 1);
    },
    setApiaryButton(state, value) {
      state.apiaryButton = value;
    },
    setIsHiddenApiaryButton(state, value) {
      state.isHiddenApiaryButton = value;
    },
    toggleApiaryActive(state, payload) {
      let apiaryId = payload.apiaryId;
      let active = payload.active;
      
      let inventory = JSON.parse(JSON.stringify(state.inventory));
      let apiary = inventory.find(item => item.id == apiaryId);
      apiary.active = active;
      state.inventory = {};
      state.inventory = inventory;
    },
  },
  actions: {
    getInventory({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaries`).then(res => {
          commit("setInventory", res.data.result.apiaries);
          resolve();
        }).catch(er => {
          reject(er);
        })
      })
    },
    addApiary({commit}, apiary) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaries`, {
          apiaryFunction: apiary.apiaryFunction,
          description: apiary.description,
          forage: apiary.forage,
          name: apiary.name,
          lat: apiary.lat,
          lng: apiary.lng,
          hiveCount: apiary.hiveCount
        }).then(res => {
          resolve();
        }).catch(er => {
          reject(er);
        })
      })
    },
    updateApiary({commit}, apiary) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaries/${apiary.id}`, {
          apiaryFunction: apiary.apiaryFunction,
          description: apiary.description,
          forage: apiary.forage,
          name: apiary.name,
        }).then(res => {
          commit('updateApiary', res.data.result.apiary);
          resolve();
        }).catch(er => {
          reject(er);
        })
      })
    },
    deleteApiary({commit}, apiary) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaries/${apiary.id}`).then(res => {
          commit("deleteApiary", apiary);
          resolve();
        }).catch(er => {
          reject(er);
        })
      })
    },
    updateSelectedApiary({commit}, apiary) {
      return new Promise((resolve, reject) => {
        commit('selectedApiary', apiary);
        resolve();
      });
    },
    setEditApiary({commit}, apiary) {
      return new Promise((resolve, reject) => {
        commit('setEditApiary', apiary);
        resolve();
      });
    },
    setEditApiaryProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setEditApiaryProp', payload);
        resolve();
      });
    },
    setApiaryButton({commit}, value) {
      return new Promise((resolve, reject) => {
        commit('setApiaryButton', value);
        resolve();
      });
    },
    setIsHiddenApiaryButton({commit}, value) {
      return new Promise((resolve, reject) => {
        commit('setIsHiddenApiaryButton', value);
        resolve();
      });
    },
    setApiariesWithRatio({commit}, apiaries) {
      return new Promise((resolve, reject) => {
        commit('setApiariesWithRatio', apiaries);
        resolve();
      });
    },
    toggleApiaryActive({commit}, payload) {
      return new Promise((resolve, reject) => {
        let apiaryId = payload.apiaryId;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/apiaries/${apiaryId}/updateactive`, {
          active: payload.active
        }).then(res => {
          commit('toggleApiaryActive', payload);
          resolve();
        }).catch(er => {
          reject(er);
        })
      });
    },
  }
}
