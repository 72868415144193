<template>
  <div>
    <v-app-bar 
      id="app-bar"
      app
      flat
      color="#ffffff"
      light
      clipped-left
      :extension="showExtension"
      :class="{'app-bar-extension': showExtension}"
    >
      <v-btn icon @click="openDrawer = !openDrawer" class="my-menu-btn">
        <img src="@/assets/Menu-right.svg">
      </v-btn>
      <v-img
        alt="BeeNotes Logo"
        class="shrink beenotes-logo"
        contain
        min-width="44"
        src="@/assets/beenotes_logo.svg"
        width="40"
        @click="goToHivesPage"
      />

      <v-spacer></v-spacer>
      <div class="d-flex align-center" id="right-menu">
        <v-select
          id="apiary-select"
          :items="inventory"
          :disabled="apiaryButton"
          v-model="apiary"
          @change="updateApiaryHives"
          item-text="name"
          item-value="id"
          label="Apiary Name"
          placeholder="Apiary Name"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          :class="{'hidden-select-box': showExtension || isHiddenApiaryButton}"
          class="ml-sm-4 mr-sm-4 mr-md-5 my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <span class="apiary-item">{{data.item.name}}</span>
          </template>
          <template slot="selection" slot-scope="data">
            <span class="apiary-select">{{data.item.name}}</span>
          </template>
        </v-select>

        <v-menu offset-y min-width="118" content-class="language-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" v-on="on">
              <img src="@/assets/Language.svg">
            </v-btn>
          </template>
          <v-list>
            <v-list-item link v-for="(item,index) in languages" :key="index"
              @click="changeLanguage(item)">
              <v-list-item-title
                :class="[item.active ? 'active-list-item' : 'inactive-list-item']"
              >
                {{item.title}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon class="mr-2" @click="goToSettings">
          <img src="@/assets/Settings.svg">
        </v-btn>

        <v-avatar
          color="#EFF0F6"
          size="48"
        >
          <span class="avatar-initials">{{getUserInitials}}</span>
        </v-avatar>
      </div>

      <template v-slot:extension v-if="showExtension">
        <v-select
          id="apiary-select"
          :items="inventory"
          :disabled="apiaryButton"
          v-model="apiary"
          @change="updateApiaryHives"
          item-text="name"
          item-value="id"
          label="Apiary Name"
          placeholder="Apiary Name"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          :class="{'hidden-select-box': isHiddenApiaryButton}"
          class="my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <span class="apiary-item">{{data.item.name}}</span>
          </template>
          <template slot="selection" slot-scope="data">
            <span class="apiary-select">{{data.item.name}}</span>
          </template>
        </v-select>
      </template>
    </v-app-bar>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <NavigationDrawer
      :isNavigationDrawerOpen="openDrawer"
      @closeDrawer="closeDrawer"
      @data-loading="$emit('page-loading')"
      @data-loading-complete="$emit('page-loading-complete')"
    ></NavigationDrawer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';

  export default {
    name: 'NavigationBar',
    components: {
      NavigationDrawer
    },
    computed: {
      ...mapGetters([
        "inventory",
        "selectedApiary",
        "latestInspectionDate",
        "apiaryButton",
        "inspectionDates",
        "apiaryHives",
        "user",
        "isHiddenApiaryButton",
        "selectedInspectionDateObj",
        "seasonalFromDate",
        "seasonalToDate",
        "chosenDateDuration"
      ]),
      apiary: {
        get() {
          return this.selectedApiary;
        },
        set(value) {
          let apiaryObj = this.inventory.find(item => item.id == value);
          this.$store.commit('selectedApiary', apiaryObj);
        }
      },
      getUserInitials() {
        let result = '';
        let first = '';
        let second = '';
        if (Object.keys(this.user).length != 0) {
          first = this.user.firstname.slice(0, 1);
          second = this.user.lastname.slice(0, 1);
        }
        result = first + second;
        return result;
      },
      showExtension() {
        let x = window.matchMedia("(max-width: 600px)");
        return x.matches && !this.isHiddenApiaryButton;
      }
    },
    data: () => ({
      openDrawer: false,
      showLoader: false,
      languages: [
        {title: 'English', lang: 'en', active: false},
        {title: 'Ελληνικά', lang: 'el', active: false},
        {title: 'Español', lang: 'es', active: false}
      ]
    }),
    mounted() {
      for (let k = 0; k < this.languages.length; k++) {
        let item = this.languages[k];
        if (item.lang == this.$i18n.locale) {
          item.active = true;
        }
      }
    },
    methods: {
      ...mapActions([
        "getInspectionDates",
        "getApiaryHives",
        "getUncompletedActions",
        "setLatestInspectionDate",
        "setSelectedInspectionDate",
        "setActionDetails",
        "inspectionGroupMetrics",
        "resetCharts",
        "resetSeasonalCharts",
        "seasonalGroupMetrics",
        "setSeasonalFromToDates",
        "resetSeasonalDates",
        "setCompletedTitle"
      ]),
      updateApiaryHives() {
        let page = this.$route.name;
        this.showLoader = true;
        let apiaryId = this.selectedApiary.id;

        this.getInspectionDates(apiaryId).then(() => {
          if (this.inspectionDates.length != 0) {
            let inspectionGroupId = this.selectedApiary.last_group_id;
            let latestInspection = {
              date: moment(this.selectedApiary.last_group_date).format("YYYY/MM/DD"),
              id: inspectionGroupId
            };
            this.setLatestInspectionDate(latestInspection).then(() => {
              if (page == 'hives' || page == 'edit_hives') {
                let payload = {
                  'apiaryId': apiaryId,
                  'groupId': inspectionGroupId
                };
                this.getApiaryHives(payload).then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'inspection_analysis') {
                this.getInspectionData(inspectionGroupId);
              } else if (page == 'seasonal_analysis') {
                this.getSeasonalData(inspectionGroupId);
              } else if (page == 'hive_management') {
                this.getActionsHistory();
              }
            });
            this.setSelectedInspectionDate(latestInspection.date);
          } else {
            this.setLatestInspectionDate({}).then(() => {
              if (page == 'hives' || page == 'edit_hives') {
                let payload = {
                  'apiaryId': apiaryId,
                };
                this.getApiaryHives(payload).then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'inspection_analysis') {
                this.resetCharts().then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'seasonal_analysis') {
                this.resetSeasonalCharts().then(() => {
                  this.showLoader = false;
                });
              } else if (page == 'hive_management') {
                this.getActionsHistory();
              }
            });
            this.setSelectedInspectionDate('');
          }
        });
      },
      getInspectionData(inspectionGroupId) {
        let promises = [];
        let metrics = [
          'generalStatus',
          'queenColor',
          'populationFrames',
          'broodFrames',
          'honeycombs',
          'harvestedFrames'
        ];

        this.resetCharts().then(() => {
          for (let k = 0; k < metrics.length; k++) {
            let promise = this.getInspectionMetric(metrics[k], inspectionGroupId);
            promises.push(promise);
          }

          Promise.all(promises).then((res) => {
            this.showLoader = false;
          });
        });
      },
      getInspectionMetric(field, inspectionGroupId) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            ig_id: inspectionGroupId,
            field: field
          };
          this.inspectionGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        })
      },
      getSeasonalData(inspectionGroupId) {
        let promises = [];
        let metrics = [
          'generalStatus',
          'populationFramesS',
          'broodFramesS',
          'harvestedFramesS'
        ];

        this.resetSeasonalDates();
        this.resetSeasonalCharts().then(() => {
          this.updateSeasonalDateRange().then(() => {
            for (let k = 0; k < metrics.length; k++) {
              let promise = this.getSeasonalMetric(metrics[k]);
              promises.push(promise);
            }

            Promise.all(promises).then((res) => {
              this.showLoader = false;
            });
          });
        });
      },
      updateSeasonalDateRange() {
        return new Promise((resolve, reject) => {
          let fromDate = moment().subtract(this.chosenDateDuration, "days").format("YYYY-MM-DD");
          let toDate = moment().format("YYYY-MM-DD");

          let payload = {
            fromDate: fromDate,
            toDate: toDate
          };
          
          this.setSeasonalFromToDates(payload).then(() => {
            resolve();
          });
        });
      },
      getSeasonalMetric(field) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            date_from: this.seasonalFromDate,
            date_to: this.seasonalToDate,
            field: field
          };
          this.seasonalGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        });
      },
      changeLanguage(item) {
        for (let k = 0; k < this.languages.length; k++) {
          this.languages[k].active = false;
        }
        item.active = true;

        this.$i18n.locale = item.lang;

        if (item.lang != this.$route.params.lang) {
          var pageName = this.$route.path.split("/").pop();
          this.$router.push(`/${item.lang}/${pageName}`);
        }
      },
      goToHivesPage() {
        if (this.$route.name != 'hives') {
          this.setActionDetails({});
          if (this.inventory.length != 0) {
            let inspectionGroupId = this.selectedInspectionDateObj.id;
            this.getApiaryHives({
              'apiaryId': this.selectedApiary.id,
              'groupId': inspectionGroupId,
            }).then(() => {
              this.$router.push({path: 'hives'});
            });
          } else {
            this.$router.push({path: 'hives'});
          }
        }
      },
      getActionsHistory() {
        this.showLoader = true;
        let payload = {
          'apiaryId': this.selectedApiary.id
        };
        this.setActionDetails({});
        this.setCompletedTitle('viewCompleted');
        this.getUncompletedActions(payload).then(() => {
          this.showLoader = false;
        });
      },
      goToSettings() {
        if (this.$route.name != 'settings') {
          this.$router.push({path: 'settings'});
        }
      },
      closeDrawer(isOpen) {
        this.openDrawer = isOpen;
      }
    }
  };
</script>

<style type="text/css">
  #app-bar,
  #app-bar .v-toolbar__content {
    height: 70px !important;
  }

  #app-bar button.my-menu-btn {
    margin-left: 10px;
  }

  #app-bar .beenotes-logo {
    margin-left: 16px;
  }

  .beenotes-logo:hover {
    cursor: pointer;
  }

  .language-menu .v-list {
    background-color: #EFF0F6;
  }

  .active-list-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  .inactive-list-item {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
  }

  #app-bar .hidden-select-box {
    visibility: hidden;
    display: none;
  }

  .avatar-initials {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
    text-transform: uppercase;
  }

  .apiary-item {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
  }

  .apiary-select,
  #my-app .v-list-item--active .apiary-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  @media screen and (max-width: 600px) {
    #app-bar.app-bar-extension {
      height: 128px !important;
    }

    #app-bar button.my-menu-btn {
      margin-left: -10px;
    }

    #app-bar .beenotes-logo {
      margin-left: 8px;
    }
  }
</style>