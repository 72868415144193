import axios from "axios";
import moment from "moment";

export default {
  state: {
    apiaryHives: [],
    selectedHives: [],
    hiveDetails: {},
    hiveDetailsModalTabs: [
      {key: 0, active: true, icon: 'heart', name: 'status', component: 'status-tab'},
      {key: 1, active: false, icon: 'lightning', name: 'actions', component: 'actions-tab'},
      {key: 2, active: false, icon: 'chart', name: 'graphs', component: 'diagrams-tab'}
    ],
    hiveDetailsActiveTab: 0,
    hiveNumber: {},
    newHiveTemplate: {
      dateCreated: '',
      apiaryName: '',
      hiveId: '',
      generalStatus: '',
      broodFrames: '',
      populationFrames: '',
      honeycombs: '',
      harvestedFrames: ''
    },
    hiveBroodFrames: [],
    hivePopulationFrames: [],
    hiveBroodPopulationFrames: [],
    hiveHarvestedFrames: [],
    hiveChosenDateDuration: 365,
    hiveCustomDateDuration: null
  },
  getters: {
    apiaryHives: state => state.apiaryHives,
    selectedHives: state => state.selectedHives,
    hiveDetails: state => state.hiveDetails,
    hiveDetailsModalTabs: state => state.hiveDetailsModalTabs,
    hiveDetailsActiveTab: state => state.hiveDetailsActiveTab,
    hiveNumber: state => state.hiveNumber,
    newHiveTemplate: state => state.newHiveTemplate,
    hiveBroodFrames: state => state.hiveBroodFrames,
    hivePopulationFrames: state => state.hivePopulationFrames,
    hiveBroodPopulationFrames: state => state.hiveBroodPopulationFrames,
    hiveHarvestedFrames: state => state.hiveHarvestedFrames,
    hiveChosenDateDuration: state => state.hiveChosenDateDuration,
    hiveCustomDateDuration: state => state.hiveCustomDateDuration
  },
  mutations: {
    setApiaryHives(state, apiaryHives) {
      state.apiaryHives = [];
      state.apiaryHives = apiaryHives.sort((a, b) => a.hiveNumber - b.hiveNumber);
    },
    setSelectedHives(state, hives) {
      state.selectedHives = hives.sort((a, b) => a.hiveNumber - b.hiveNumber);
    },
    setHiveDetails(state, hiveObj) {
      state.hiveDetails = hiveObj;
    },
    setHiveDetailsProp(state, payload) {
      state.hiveDetails[payload.prop] = payload.value;
    },
    setHiveDetailsActiveTab(state, index) {
      for (let k = 0; k < state.hiveDetailsModalTabs.length; k++) {
        state.hiveDetailsModalTabs[k].active = false;
      }
      state.hiveDetailsActiveTab = index;
      state.hiveDetailsModalTabs[index].active = true;
    },
    setHiveDetailsNewInspectionTab(state, index) {
      state.hiveDetailsModalTabs.find( el => el.key === 0).component = 'inspections-status-tab';
    },
    resetHiveDetailsTabs(state, index) {
      state.hiveDetailsModalTabs.find( el => el.key === 0).component = 'status-tab';
    },
    setHiveNumber(state, hiveObj) {
      state.hiveNumber = hiveObj;
    },
    setHiveNumberProp(state, payload) {
      state.hiveNumber[payload.prop] = payload.value;
    },
    setHiveBroodFrames(state, resultArray) {
      state.hiveBroodFrames = resultArray;
    },
    setHivePopulationFrames(state, resultArray) {
      state.hivePopulationFrames = resultArray;
    },
    setHiveBroodPopulationFrames(state) {
      let chartData = [];

      const map = new Map();
      state.hiveBroodFrames.forEach(item => map.set(item.date, item));
      state.hivePopulationFrames.forEach(item => map.set(item.date, {...map.get(item.date), ...item}));
      const mergedArr = Array.from(map.values());

      if (mergedArr.length != 0) {
        chartData = [
          [
            {type: 'date', label: 'Date'},
            'Brood Frames',
            {type: 'string', role: 'tooltip','p': {'html': true}},
            'Population Frames',
            {type: 'string', role: 'tooltip','p': {'html': true}}
          ]
        ];
      }

      for (let k = 0; k < mergedArr.length; k++) {
        let date = mergedArr[k].date;
        let dateObj = new Date(date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let broodValue = null;
        if (mergedArr[k].broodFrames) {
          broodValue = parseFloat(mergedArr[k].broodFrames);
        }
        let broodTooltip = '<div class="graph-tooltip">'+date+'<br>Value: '+broodValue+'</div>';

        let populationValue = null;
        if (mergedArr[k].populationFrames) {
          populationValue = parseFloat(mergedArr[k].populationFrames);
        }
        let popTooltip = '<div class="graph-tooltip">'+date+'<br>Value: '+populationValue+'</div>';

        chartData.push([
          new Date(year, month, day),
          broodValue,
          broodTooltip,
          populationValue,
          popTooltip
        ]);
      }

      state.hiveBroodPopulationFrames = chartData;
    },
    setHiveHarvestedFrames(state, resultArray) {
      let chartData = [
        [
          {type: 'date', label: 'Date'},
          'Harvested Frames',
          {type: 'string', role: 'tooltip','p': {'html': true}}
        ]
      ];

      for (let k = 0; k < resultArray.length; k++) {
        let date = resultArray[k].date;
        let dateObj = new Date(date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let day = dateObj.getDate();

        let value = parseFloat(resultArray[k].harvestedFrames);
        let tooltip = '<div class="graph-tooltip">'+date+'<br>Value: '+value+'</div>';

        chartData.push([new Date(year, month, day), value, tooltip]);
      }

      state.hiveHarvestedFrames = chartData;
    },
    setHiveChosenDateDuration(state, value) {
      state.hiveChosenDateDuration = value;
    },
    setHiveCustomDateDuration(state, value) {
      state.hiveCustomDateDuration = value;
    },
    selectHive(state, hiveId) {
      let hive = state.apiaryHives.find(item => item.id == hiveId);
      hive.selected = !hive.selected;
      if (hive.selected) {
        state.selectedHives.push(hive);
      } else if (!hive.selected && state.selectedHives.length != 0) {
        state.selectedHives = state.selectedHives.filter(item => {return item.id != hiveId})
      }
      state.selectedHives.sort((a, b) => a.hiveNumber - b.hiveNumber);
    },
    toggleHiveActive(state, payload) {
      let hiveId = payload.hiveId;
      let active = payload.active;
      let hive = state.apiaryHives.find(item => item.id == hiveId);

      hive.active = active;
    },
    toggleHiveActiveMult(state, payload) {
      let hives_selected = payload.hives_selected;
      let active = payload.active;

      for (let k = 0; k < hives_selected.length; k++) {
        let hiveId = hives_selected[k];
        let hive = state.apiaryHives.find(item => item.id == hiveId);
        hive.active = active;
      }
    },
    toggleHiveStarred(state, payload) {
      let hiveId = payload.hiveId;
      let starred = payload.starred;
      let hive = state.apiaryHives.find(item => item.id == hiveId);

      hive.starred = starred;
    },
    deleteHiveMult(state, payload) {
      state.selectedHives = [];
    },
    resetAllHives(state) {
      state.apiaryHives = [];
      state.selectedHives = [];
    },
    resetSelectedHives(state) {
      state.selectedHives = [];
      state.apiaryHives = state.apiaryHives.map((item) => {
        item.selected = false;
        return {...item};
      });
    },
    resetHiveDetails(state) {
      state.hiveDetails = {};
    },
    resetHiveGraphs(state) {
      state.hiveBroodFrames = [];
      state.hivePopulationFrames = [];
      state.hiveHarvestedFrames = [];
    },
    resetHiveDates(state) {
      state.hiveChosenDateDuration = 365;
      state.hiveCustomDateDuration = null;
    },
    rerenderGraphsTab(state) {
      let graphsTab = state.hiveDetailsModalTabs.find(item => item.name == 'graphs');
      graphsTab.key += 1;
    }
  },
  actions: {
    getApiaryHives({commit}, payload) {
      return new Promise((resolve, reject) => {
        var url = `${process.env.VUE_APP_BASE_URL}/api/v1/hivesWithStatus`;
        axios({
          method: 'get',
          url: url,
          params: {
            'apiary_id': payload.apiaryId,
            'group_id': payload.groupId
          }
        })
        .then(res => {
          let tmp = [];
          for (var x of res.data.result.hives) {
            tmp.push(x);
          }
          for (x of tmp) {
            x.selected = false;
          }
          commit('setApiaryHives', tmp);
          resolve();
        })
        .catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    getHiveAnalysis({commit}, payload) {
      let chartType = payload.field;
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/get-hive-analysis`, {
          ...payload
        }).then(res => {
          if (res.data.data.length != 0) {
            let dataArray = res.data.data.sort(
              (a, b) => moment(a.date, "YYYY/MM/DD") - moment(b.date, "YYYY/MM/DD")
            );
            if (chartType == 'broodFrames') {
              commit('setHiveBroodFrames', dataArray);
            } else if (chartType == 'populationFrames') {
              commit('setHivePopulationFrames', dataArray);
            } else if (chartType == 'harvestedFrames') {
              commit('setHiveHarvestedFrames', dataArray);
            }
          }
          resolve();
        })
        .catch(er => {
          console.log(er);
          reject();
        })
      })
    },
    setHiveDetails({commit}, hiveObj) {
      return new Promise((resolve, reject) => {
        commit('setHiveDetails', hiveObj);
        resolve();
      });
    },
    setHiveDetailsProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setHiveDetailsProp', payload);
        resolve();
      });
    },
    setHiveDetailsActiveTab({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setHiveDetailsActiveTab', payload);
        resolve();
      });
    },
    setHiveDetailsNewInspectionTab({commit}) {
      return new Promise((resolve, reject) => {
        commit('setHiveDetailsNewInspectionTab');
        resolve();
      });
    },
    resetHiveDetailsTabs({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveDetailsTabs');
        resolve();
      });
    },
    setHiveNumber({commit}, hiveObj) {
      return new Promise((resolve, reject) => {
        commit('setHiveNumber', hiveObj);
        resolve();
      });
    },
    setHiveNumberProp({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setHiveNumberProp', payload);
        resolve();
      });
    },
    setHiveChosenDateDuration({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setHiveChosenDateDuration', date);
        resolve();
      })
    },
    setHiveCustomDateDuration({commit}, date) {
      return new Promise((resolve, reject) => {
        commit('setHiveCustomDateDuration', date);
        resolve();
      })
    },
    setHiveBroodPopulationFrames({commit}) {
      return new Promise((resolve, reject) => {
        commit('setHiveBroodPopulationFrames');
        resolve();
      })
    },
    selectHive ({commit}, hiveId) {
      return new Promise((resolve, reject) => {
        commit('selectHive', hiveId);
        resolve();
      });
    },
    toggleHiveActive({commit}, payload) {
      return new Promise((resolve, reject) => {
        let hiveId = payload.hiveId;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/hives/${hiveId}/updateactive`, {
          active: payload.active
        }).then(res => {
          commit('toggleHiveActive', payload);
          resolve();
        }).catch(er => {
          reject(er);
        })
      });
    },
    toggleHiveStarred({commit}, payload) {
      return new Promise((resolve, reject) => {
        let hiveId = payload.hiveId;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/hives/${hiveId}/updatestarred`, {
          starred: payload.starred
        }).then(res => {
          commit('toggleHiveStarred', payload);
          resolve();
        }).catch(er => {
          reject(er);
        })
      });
    },
    toggleHiveActiveMult({commit}, payload) {
      return new Promise((resolve, reject) => {
        let hiveId = payload.hiveId;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/hives/updateactivemult`, {
          hives_selected: payload.hives_selected,
          active: payload.active
        }).then(res => {
          commit('toggleHiveActiveMult', payload);
          resolve();
        }).catch(er => {
          reject(er);
        })
      });
    },
    deleteHiveMult({commit}, payload) {
      return new Promise((resolve, reject) => {
        let hiveId = payload.hiveId;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/hives/deletemult`, {
          hives_selected: payload.hives_selected
        }).then(res => {
          commit('deleteHiveMult', payload);
          resolve();
        }).catch(er => {
          reject(er);
        })
      });
    },
    resetAllHives ({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetAllHives');
        resolve();
      });
    },
    resetSelectedHives ({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetSelectedHives');
        resolve();
      });
    },
    resetHiveDetails({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveDetails');
        resolve();
      });
    },
    resetHiveGraphs({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveGraphs');
        resolve();
      });
    },
    resetHiveDates({commit}) {
      return new Promise((resolve, reject) => {
        commit('resetHiveDates');
        resolve();
      });
    },
    rerenderGraphsTab({commit}) {
      return new Promise((resolve, reject) => {
        commit('rerenderGraphsTab');
        resolve();
      });
    }
  }
}
