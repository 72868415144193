<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3641 18.364C12.8493 21.8787 7.15086 21.8787 3.63614 18.364C0.121426 14.8492 0.121426 9.15076 3.63614 5.63604C7.15086 2.12132 12.8493 2.12132 16.3641 5.63604" stroke="#262338" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 9L22.6667 12L20 15" stroke="#262338" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.5 12L10 12" stroke="#262338" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'LogoutIcon'
  }
</script>
