<template>
  <div id="nav-drawer-container">
    <v-navigation-drawer
      v-model="openDrawer"
      app
      clipped
      temporary
      hide-overlay
      floating
      width="400"
      class="my-nav"
      @input="setDrawerState"
    >
      <v-list-item link @click="goToPage('apiaries')">
        <v-list-item-icon>
          <v-img src="@/assets/flag.svg"></v-img>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title id="nav-title">
            {{$t("apiaries2")}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense id="nav-menu">

        <v-list-item link class="my-4" @click="goToHivesPage">
          <v-list-item-icon>
            <v-img src="@/assets/box.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t("hives")}}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item link class="my-4" @click="goToPage('hive_management')">
          <v-list-item-icon>
            <v-img src="@/assets/lightning.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t("actions")}}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-group append-icon="mdi-chevron-right" class="my-4">
          <template v-slot:activator>
            <v-list-item-icon>
            <v-img src="@/assets/chart-pie.svg"></v-img>
          </v-list-item-icon>
            <v-list-item-title>{{$t("analytics")}}</v-list-item-title>
          </template>
          <v-list-item link @click="goToInspectionAnalysis">
            {{$t("inspectionAnalysis")}}
          </v-list-item>
          <v-list-item link @click="goToSeasonalAnalysis">
            {{$t("seasonalAnalysis")}}
          </v-list-item>
        </v-list-group>

        <v-list-group append-icon="mdi-chevron-right" class="my-4">
          <template v-slot:activator>
            <v-list-item-icon>
            <v-img src="@/assets/Settings.svg"></v-img>
          </v-list-item-icon>
            <v-list-item-title>{{$t("settings")}}</v-list-item-title>
          </template>

          <v-list-item
            link
            @click="goToSettings(0)"
          >
            {{$t("users")}}
          </v-list-item>

          <v-list-item
            link
            @click="goToSettings(1)"
          >
            {{$t("billing")}}
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-btn block depressed height="56" @click="logoutAction" class="logout-btn">
          <v-icon class="mr-4">{{logout_icon}}</v-icon>
          {{$t("logout")}}
        </v-btn>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'NavigationDrawer',
    props: ['isNavigationDrawerOpen'],
    computed: {
      ...mapGetters([
        "selectedApiary",
        "selectedInspectionDateObj",
        "inventory"
      ]),
      openDrawer: {
        get() {
          return this.isNavigationDrawerOpen;
        },
        set(value) {
          null;
        }
      }
    },
    data () {
      return {
        logout_icon: "$vuetify.icons.logout_icon"
      }
    },
    methods: {
      ...mapActions([
        "logout",
        "resetCharts",
        "getApiaryHives",
        "setSettingsActiveTab",
        "inspectionGroupMetrics",
        "setActionDetails"
      ]),
      setDrawerState(isOpen) {
        this.$emit("closeDrawer", isOpen);
      },
      goToPage(page) {
        if (this.$route.name != page) {
          this.setActionDetails({});
          this.$router.push({path: page});
        } else {
          this.$emit("closeDrawer", false);
        }
      },
      goToInspectionAnalysis() {
        if (this.$route.name != 'inspection_analysis') {
          let promises = [];
          let metrics = [
            'generalStatus',
            'queenColor',
            'populationFrames',
            'broodFrames',
            'honeycombs',
            'harvestedFrames'
          ];
          
          this.$emit("closeDrawer", false);
          this.$emit('data-loading');

          let inspectionGroupId = this.selectedInspectionDateObj.id;

          this.resetCharts().then(() => {
            for (let k = 0; k < metrics.length; k++) {
              let promise = this.getInspectionMetric(metrics[k], inspectionGroupId);
              promises.push(promise);
            }

            Promise.all(promises).then((res) => {
              this.$emit('data-loading-complete');
              this.$router.push({path: 'inspection_analysis'});
            });
          });
        } else {
          this.$emit("closeDrawer", false);
        }
      },
      goToSeasonalAnalysis() {
        if (this.$route.name != 'seasonal_analysis') {
          this.$router.push({path: 'seasonal_analysis'});
        } else {
          this.$emit("closeDrawer", false);
        }
      },
      getInspectionMetric(field, inspectionGroupId) {
        let payload = {
          apiary_id: this.selectedApiary.id,
          ig_id: inspectionGroupId,
          field: field
        };
        this.inspectionGroupMetrics(payload);
      },
      goToHivesPage() {
        if (this.$route.name != 'hives') {
          this.setActionDetails({});
          if (this.inventory.length != 0) {
            let inspectionGroupId = this.selectedInspectionDateObj.id;
            this.$emit("closeDrawer", false);
            this.$emit('data-loading');

            this.getApiaryHives({
              'apiaryId': this.selectedApiary.id,
              'groupId': inspectionGroupId,
            }).then(() => {
              this.$emit('data-loading-complete');
              this.$router.push({path: 'hives'});
            });
          } else {
            this.$router.push({path: 'hives'});
          }
        } else {
          this.$emit("closeDrawer", false);
        }
      },
      goToSettings(activeTab) {
        this.setSettingsActiveTab(activeTab).then(() => {
          this.goToPage('settings');
        });
      },
      logoutAction() {
        this.logout();
        this.$router.go("login");
      }
    }
  }
</script>

<style type="text/css">
  #nav-drawer-container {
    position: relative;
  }

  #nav-drawer-container .my-nav {
    box-shadow: none;
    padding: 32px;
    margin-top: 70px;
    height: calc(100vh - 70px) !important;
  }

  #nav-drawer-container .v-list-item__icon,
  #nav-drawer-container .v-list-item__action {
    margin: 16px 16px 16px 0;
  }

  #nav-drawer-container .v-list-group__items .v-list-item {
    margin: 15px 0 15px 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #262338 !important;
  }

  #nav-drawer-container .v-icon {
    color: #262338;
  }

  #my-app #nav-menu .v-list-item__title {
    font-size: 15px;
    font-weight: 600;
    color: #262338;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  #my-app #nav-title.v-list-item__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #262338;
    letter-spacing: 0.75px;
  }

  #nav-drawer-container .logout-btn {
    background: #EFF0F7;
    border-radius: 12px;
    padding: 16px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    justify-content: left;
    text-transform: capitalize;
  }

  #nav-drawer-container .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(90deg);
  }
</style>